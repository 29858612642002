.live-video {
    width: 100%;
    height: calc(100vh - 130px);
    position: relative;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.50);
}
.miccameramain {
    position: absolute;
    top: 62px;
    z-index: 99;
    right: 12px; 
}
.micmain {
    width: 75px;
    height: 75px;
    background: #ffffff33;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}
.endbutton {
    position: absolute;
    bottom: 44px;
    z-index: 99;
    left: 0;
    right: 0;
    text-align: center;
}
.endinnerbutton {
    border-radius: 30px 10px;
    border: 0;
    background:var(--theme_pink);
    color: #fff;
    height: 61px;
    width: 331px;
    font-size: 20px;
    font-weight: 600;
}
.livetime-button {
    position: absolute;
    top: 44px;
    z-index: 99;
    left: 37px;
    background: var(--theme_blue);
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border-radius: 5px;
    height: 48px;
    padding: 12px 20px;
}
.live-video:nth-child(4) {
    background-color: transparent !important; 
}
.mutevideo{
    display: none;
}
.mutedvideo p {
    color: #07143A;
    font-family: Gilroy;
    font-size: 52.241px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.522px;
    text-transform: uppercase;
}
.mutedvideo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 100%;
    background-image:url("../../../public/assets/images/creater-dashboard/circleLive.svg");
    background-repeat: no-repeat;
    background-position: center;

}
.chat-listing {
    display: grid;
    align-items: start;
    grid-template-columns: 45px auto 20px;
    gap: 12px;
}
.profile-chat img {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    border: 2px solid #ca3392;
}
.chat-right {
    border-radius: 30px;
    background: #FFF;
    height: 100%;

}
.chat-message {
    padding: 10px 27px 27px;
    height: 600px;
    overflow-y: auto;
}
.chat-heading {
    border-radius: 30px 30px 0px 0px;
    background: #F0F0F0;
    padding: 18px 27px;
}
.chat-bottom .profile-bottom {
    width: 54px;
    height: 54px;
    border-radius: 50px;
    object-fit: cover;
    border: 1px solid #07143A;
}
.chat-bottom {
    border-radius: 50px;
    background: #FFF;
    box-shadow: 4px 4px 100px 0px rgba(0, 0, 0, 0.10);
    height: 70px;
    display: flex;
    align-items: center;
    position: absolute;
    width: 92%;
    bottom: 27px;
    padding: 18px;
    gap: 11px;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
}
.chat-bottom input {
    font-size: 19px;
}
.para-chat{
    color:#5F5F5F;
}
.chat-time{
    color: #757D7E;
}
.new-chat-msg .get-msg {
    height: 76%;
    /* 76 */
    overflow-y: scroll;
}
.record-video video {
    /* height: 85%; */
    max-height: 620px;
}
.new-series-chat{
    position: fixed;
    width: 26.5%;
    /* top: 13.5%;  */
}
.new-series-chat .chat-right{
    height: 54.5%;
}
.cus-series-chat .chat-right{
    height: 86%;
}
.cus-series-chat{
    position: fixed;
    right: 20px;
    width: 26.5%;
    padding-left: 20px;
    z-index: 10;
}
@media screen and (max-width:1600px) {
    .new-chat-msg .get-msg {
        height: 68%;
        overflow-y: scroll;
    }
    .record-video video {
        width: 96%;
        max-height: 452px;
    }
}
@media screen and (max-width:1200px) {
    .record-video video {
        width: 100%;
    }
    .cus-series-chat {
        width: 32.5%;
    }
}
@media screen and (max-width:992px) {
    .first-order {
        order: 1;
    }
    .second-order {
        order: 2;
    }
    .third-order {
        order: 3;
    }
    .cus-series-chat {
        position: relative;
        right: unset;
        width: 100%;
        padding-left: 0px;
    }
    .cus-series-chat .chat-right {
        height: 98%;
    }
}

@media screen and (max-width:767px) {
    .new-chat-msg .get-msg {
        height: 80%;
        overflow-y: scroll;
    }

}
.schedule-height{
    height: 570px;
    overflow-y: auto;
}