.endsecton {
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
    -webkit-backdrop-filter: blur(217px);
    backdrop-filter: blur(217px);
    margin-top: 30px;
  }
  .endsecton .navbar-brand img {
    max-width: 350px;
  }
  .endsecton ul li {
    margin: 0 20px;
  }
  .footer-bottom {
    background-color: var(--theme_pink);
    color: var(--white);
    cursor: pointer;
  }
  .fooetr-enbar {
    padding-bottom: 0 !important;
  }
  .footer-ul .active{
    color: var(--theme_pink);
  }