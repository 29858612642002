.streaming-wrapper {
    width: 100%;
  }
  .streaming-wrapper p {
    max-width: 85%;
    margin: auto;
  }

  .entertaiment-text .common-btn,
  .comunnity-wraper .common-btn {
    padding: 16px 21px;
  }
  
  .entertaiment-text .btn-blue,
  .comunnity-wraper .btn-blue {
    padding: 16px 45px;
  }
  
  .given-padding {
    padding-bottom: 80px !important;
  }
  
  .entertaiment-wrapper .owl-dots button.owl-dot {
    width: 39.62px;
    height: 13.12px;
    border-radius: 9.37023px;
  }
  .entertaiment-wrapper .owl-dots button.owl-dot.active {
    background: var(--theme_blue) !important;
  }
  .entertaiment-wrapper .owl-dots button.owl-dot:focus {
    outline: none;
  }
  .entertaiment-wrapper .owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.38) !important;
  }
  .entertaiment-wrapper .owl-dots {
    text-align: center;
  }
  .entertaiment-wrapper .owl-nav button:focus {
    outline: none;
  }
  
  .entertaiment-wrapper .owl-carousel .owl-dots.disabled,
  .owl-carousel .owl-nav.disabled {
    display: block !important;
  }
  .entertaiment-wrapper .owl-carousel button.owl-dot {
    background: #8888886e;
    margin: 0 3px;
  }
  .item-relative {
    position: relative;
  }
  .img-absolute {
    position: absolute;
    right: 140px;
    bottom: 20px;
    width: 30px;
    border: none;
    background: transparent;
  }
  .entertaiment-wrapper .owl-carousel .owl-item img {
    width: auto !important;
  }
  
  #entertainment .owl-dots .owl-nav .owl-prev span,
  #entertainment .owl-dots .owl-nav .owl-next span {
    display: none !important;
  }