
.banner-conent {
    width: 50%;
  }
  .banner-conent.banner-text {
    padding-left: 150px;
  }
  .banner-conent .banner-img {
    width: 100%;
  }
  .down-img {
    border: 2px solid var(--theme_pink);
    background-color: var(--white);
    border-radius: 30px 10px;
    padding: 13px;
  }
  
  .banner-wraper .btn-blue {
    padding: 17px 21px !important;
  }
  .banner-wraper .common-btn {
    padding: 2px 14px 2px 0;
  }
  .wrapper-border {
    border: 5px solid var(--white);
    border-radius: 90px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  }