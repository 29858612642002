.contact-back-banner {
    /* background: url(../image/Contact_us_back.png); */
    background-repeat: no-repeat;
    background-position: top center;
  }
  /* .Contact-banner {
    background: url(../image/contact_banner_background.png);
    background-repeat: no-repeat;
    background-position: top center;
  } */
  /* .contact-head img {
    max-width: 85%;
    height: auto;
  } */
  /* .contact-content {
    font-size: 30px;
    line-height: 39px;
  } */
  /* .get-in-banner {
    background: url("../image/map_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  } */
  /* .get-in-banner {
    padding-bottom: 20em;
  }
  .get-card {
    border-right: 6px solid var(--theme_pink);
    border-radius: 0px 4px 4px 0px;
    padding: 10px 0px 10px 30px;
  }
  .get-card p {
    margin: 0px;
  }
  .get-card h4 {
    text-transform: uppercase;
  }
  .get-in-head {
    padding-bottom: 80px;
  }
  .get-in-form {
    margin: 0px auto;
    width: 68%;
  }
  .get-form-head {
    padding: 150px 10px 80px 10px;
  }
  .get-contact-form {
    border: none;
    background: #ffffff;
    box-shadow: 4px 4px 100px rgba(0, 0, 0, 0.07);
    padding: 20px;
    font-size: 18px;
    font-weight: 500;
  }
  .get-message {
    min-height: 245px !important;
  }
  .send-mesg-btn {
    margin: 0px auto;
  }
  .send-btn {
    padding: 16px 160px;
  } */