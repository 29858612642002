
.payemnt-label {
    font-weight: 600;
    text-align: left;
}

.StripeElement {
    display: block;
    padding: 15px 15px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    background: white;
    width: 100%;
}

.CardNumberElement,
.CardExpiryElement,
.CardCvcElement {
    height: 54px;
    border: 1px solid #D1D1D1 !important;
    color: var(--gray-text);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 32px 0 16px;
    width: 100%;
}

.cardName{
     height: 54px;
    border: 1px solid #D1D1D1 !important;
    color: var(--gray-text);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 32px 0 16px;
    width: 100%;
}
.OffsetContainer {
    bottom: var(--gutterOffset);
    left: var(--gutterOffset);
    position: absolute;
    right: var(--gutterOffset);
    top: var(--gutterOffset);
    display: none !important;
}

button:disabled {
    background-color: #aab7c4;
    cursor: not-allowed;
}
