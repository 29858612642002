.comunnity-wraper {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
    border-radius: 90px;
    border: 5px solid var(--white);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  }
  
  .join-comunity img {
    width: 100%;
  }