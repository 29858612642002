.stream-btn .btn-blue {
    padding: 16px 42px;
    border-radius: 6px 20px;
}

h4 {
    font-weight: 600;
}

.btn-white {
    padding: 16px 42px;
    color: var(--btn-color);
    background-color: var(--white);
    border: 1px solid #E3E0FE;
    border-radius: 6px 20px;
}

.btn-white:hover,
.btn-white:active {
    /* padding: 16px 42px; */
    color: var(--white);
    background-color: var(--theme_blue);
}


/* chanel start */
.chanel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.see-all {
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.chanel-content {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
    position: relative;
    border-radius: 24px 5px;
}

.chanel-content .chanl-img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
}

.chanel-text p {
    line-height: 13px;
}


.bite-inner-img {
    width: 33px;
    height: 33px;
    border-radius: 50%;
}

.bite-relative {
    position: relative;
    /* width: 100%; */
}

.bite-wrapper .common-btn {
    display: inline-flex;
    border-radius: 13px 4px;
    padding: 5px 40px;
    border: none;
    gap: 10px;
    align-items: center;
}

.btn-play {
    position: absolute;
    top: 50%;
    border: none;
    background-color: transparent;
    left: 15%;
    transform: translateY(-50%);
}

.bite-wrapper p {
    line-height: 20px;
    text-overflow: ellipsis;
}

.challenge-video {
    position: relative;
}

.img-relative {
    width: 100%;
}

.video-bottom {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.video-bottom img {
    width: 55px;
    height: 55px;
}

.video-bottom p {
    line-height: 15px;
}

.video-bottom h6 {
    font-size: 16px;
}

.video-bg {
    position: relative;
}

.bg-image {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
}


.play-icon {
    position: absolute;
    background: #cc09a5;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 28px 33px;
    bottom: 20px;
    right: 10px;
    border-color: #cc09a5;
    outline: none;
    box-shadow: none;
    border: 1px transparent;
}

.play-icon img {
    width: 49px;
}

.video-detail {
    display: flex;
}

.chllge-img {
    width: 63px;
    height: 63px;
    margin-right: 10px;
}

.grid-frame {
    display: grid;
    grid-template-columns: 500px 500px 500px;
    gap: 30px;
    cursor: pointer;
    height: 440px;
}

.grid-column {
    background-color: var(--white);
    padding: 10px;
    border-radius: 25px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
    height: 430px;
    width: 100%;
    left: 5px;
}

.challene-wrapper {
    overflow-x: scroll;
}

/* width */
::-webkit-scrollbar {
    display: none;
}

.challene-wrapper {
    width: 100%;
    overflow-x: scroll;
}

.comming-soon .owl-carousel .owl-nav.disabled {
    display: none !important;
}

.img-video,
.img-relative,
.bite-img {
    border-radius: 13px;
}

.img-video {
    width: 100%;
}

/* challenge end */

/* modal  */
.play-video span.carousel-control-prev-icon {
    width: 80px;
    height: 80px;
    background: var(--theme_pink);
    position: absolute;
    left: -120px;
    border-radius: 50%;
    font-size: 50px;
}

.play-video .carousel-control-next-icon {
    right: -120px;
    width: 80px;
    height: 80px;
    background: var(--theme_pink);
    position: absolute;
    border-radius: 50%;
    font-size: 50px;
}

.play-video .modal-content {
    background-color: transparent;
    border: none;
}

.play-video .slider-image {
    border-radius: 30px;
    box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.2);
}

.play-video .slider-images {
    border-radius: 30px;
}

.play-video .video-bottom {
    position: absolute;
    bottom: 10px;
    left: -40px;
    text-align: left;
}

.play-video button.carousel-control-prev,
.play-video button.carousel-control-next {
    width: 0;
}

.play-video .like-btn {
    background: rgba(188, 188, 188, 0.3);
    backdrop-filter: blur(6px);
    color: var(--white);
    width: 55px;
    border-radius: 10px;
    text-align: center;
    padding: 5px 0;
}

.play-video .share-btn {
    background: rgba(188, 188, 188, 0.3);
    backdrop-filter: blur(6px);
    color: var(--white);
    width: 55px;
    border-radius: 10px;
    text-align: center;
    padding: 5px 0;
    margin-top: 10px;
}

.respns-wrapper {
    position: absolute;
    bottom: 20%;
    right: 30px;
}

.slider-image {
    position: relative;
}

@media screen and (max-width:1920px) {
    .bite .bite-img {
        /* max-width: 120px; */
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    img.bite-img {
        max-height: 150px;
        min-height: 150px;
        height: 100%;
    }
}

@media screen and (max-width: 1600px) {
    /* .bite .bite-img {
        max-width: 150px;
        width: 100%;
        height: 150px;
        object-fit: cover;
    } */

    /* img.bite-img {
        max-height: 120px;
        min-height: 120px;
    } */

    /* .bite .bite-img {
        max-width: 80px;
    } */

    .chanel-content {
        gap: 12px;
        padding: 10px;
    }

    .chanel-text {
        gap: 5px !important;
        align-items: flex-start !important;
    }

    .bite-wrapper h4 {
        font-size: 18px;
    }

    .bite-wrapper .common-btn {
        padding: 5px 28px !important;
        font-size: 14px !important;
    }
}

@media screen and (max-width:1200px) {
    .chanel-content .chanl-img {
        width: 60px;
        height: 60px;
    }

    .bite .bite-relative {
        width: auto;
    }

    .bite .bite-wrapper {
        margin: 0;
    }

    .btn-play {
        top: 120px;
        left: 40%;
    }

    .bite-wrapper {
        margin: 0;
    }

    img.bite-img {
        max-height: 120px;
        min-height: 120px;
    }

    .chanel-text h5 {
        font-size: 18px;
    }
}

@media screen and (max-width:991px) {
    .bite .row.gy-4 {
        --bs-gutter-y: 0 !important;
    }

    .btn-play {
        top: 80px;
        left: 10%;
    }

    .video-bg:after {
        width: 100%;
    }


    ::-webkit-scrollbar {
        width: 20px;
        /* display: block; */
        height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px var(--btn-color);
        border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--btn-color);
        border-radius: 10px;
    }

    .play-icon img {
        width: 38px;
    }

    .play-icon {
        width: 90px;
        height: 90px;
        padding: 28px 33px;
        bottom: 30px;
        right: 0;
    }

    .response-padding {
        padding: 15px 0;
    }

    .response-col {
        padding: 15px 10px;
    }

    .channel .g-5,
    .gy-5 {
        --bs-gutter-y: 1rem !important;
    }

    .bite-wrapper {
        margin: 0;
    }

    .play-video span.carousel-control-prev-icon {
        left: -40px;
    }

    .play-video .carousel-control-next-icon {
        right: -40px;
    }

}

@media screen and (max-width:767px) {

    .bite .bite-relative {
        width: auto;
    }

    .bite-wrapper p {
        max-width: 160px;
    }

    .btn-play img {
        width: 40px;
    }

    .btn-play {
        top: 95px;
        left: 21%;
    }

    .play-video span.carousel-control-prev-icon,
    .play-video .carousel-control-next-icon {
        width: 60px;
        height: 60px;
        font-size: 40px;
    }

    .play-video span.carousel-control-prev-icon {
        left: 0;
    }

    .play-video .carousel-control-next-icon {
        right: 0;
    }

    .grid-column {
        height: 330px;
    }

    .grid-frame {
        grid-template-columns: 350px 350px 350px;
        gap: 20px;
        height: 330px;
    }

    .play-icon {
        width: 60px;
        height: 60px;
    }

    .play-icon img {
        width: 25px;
    }

    .play-icon {
        padding: 20px 24px;
    }

}

@media screen and (max-width:575px) {

    .btn-play {
        left: 15%;
    }

    .grid-frame {
        grid-template-columns: 250px 250px 250px;
        height: 300px;
    }

    .grid-column {
        height: 290px;
    }

    .chllge-img {
        width: 55px;
        height: 50px;
        object-fit: cover;
    }

    .play-icon {
        width: 60px;
        height: 60px;
        bottom: 30px;
        right: 10px;
    }

    .chanel-content {
        gap: 22px;
        padding: 20px;
    }

    .bite .bite-img {
        max-width: 40%;
    }
}

@media screen and (max-width:480px) {
    /* .bite .bite-relative {
        width: 50%;
    }

    .bite-wrapper p {
        max-width: 100%;
    } */

    .btn-play {
        top: 135px;
        left: 45%;
    }

    .play-video span.carousel-control-prev-icon {
        width: 40px;
        height: 40px;
        left: -10px;
        font-size: 27px;
    }

    .play-video .carousel-control-next-icon {
        width: 40px;
        height: 40px;
        right: -10px;
        font-size: 27px;
    }

    .video-bottom img {
        width: 35px;
        height: 35px;
    }

    .play-video .video-bottom {
        left: -30px;
    }

    .respns-wrapper {
        right: 10px;
    }

    .play-video .like-btn,
    .play-video .share-btn {
        width: 45px;
    }

    .chanel-content {
        gap: 20px;
        padding: 20px;
    }
}

@media screen and (max-width:420px) {
    .bite .bite-relative {
        width: 100%;
    }

    .btn-play {
        top: 130px;
        left: 45%;
    }

    .chanel-content {
        gap: 12px;
        padding: 15px 30px 15px 10px;
    }

    img.bite-img {
        max-height: 120px;
        min-height: 120px;
    }

    .bite-wrapper h4 {
        font-size: 18px !important;
    }

    .bite .bite-wrapper .common-btn {
        padding: 5px 25px;
        font-size: 16px;
    }

    /* .bite .bite-img {
        max-width: 50%;
    } */

}

@media screen and (max-width:320px) {
    .bite .bite-wrapper .common-btn {
        padding: 5px 20px !important;
        font-size: 14px !important;
    }

    .bite-wrapper h4 {
        font-size: 16px !important;
    }

    .chanel-text h5,
    .chanel-text p {
        font-size: 15px;
        margin: 5px 0;
    }

    img.bite-img {
        max-height: 105px;
        min-height: 100px;
    }

    .bite .bite-img {
        max-width: auto;
    }
}