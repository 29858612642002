.chanel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.see-all {
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.chanel-content {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 15px;
    border-radius: 20px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
    position: relative;
    border-radius: 28px !important;
}

.like-img {
    position: absolute;
    right: 15px;
    top: 10px;
}

.chanel-content .chanl-img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    object-fit: contain;
    background-color: #fff;
}

.chanel-text p {
    line-height: 10px;
}

img.bite-img {
    border-radius: 20px;
}

@media (min-width:993px) and (max-width:1400px) {
    h4 {
        font-size: 18px !important;
    }

    .chanel-content .chanl-img {
        width: 60px !important;
        height: 60px !important;
    }
}