.latest-video {
    background: #fdfdfe;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
    border-radius: 28px;
    width: 100%;
}

.latest-vid-head {
    color: var(--black);
}

.latest-vid-img {
    border-radius: 16px;
    width: 100%;
    cursor: pointer;
}

.play-vid-btn {
    position: absolute;
    top: 45%;
    left: 45%;
}

.latest-video-info p {
    color: #9DA4BF;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    line-height: 27px;
}

.video-time {
    position: absolute;
    bottom: 10px;
    right: 38px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;
}

.watch-btn {
    font-size: 16px;
    padding: 0 24px !important;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    height: 40px !important;
    border: 1px solid var(--theme_pink);
}

.watch-btn img {
    width: 15px;
}

.like-btn {
    background: rgba(188, 188, 188, 0.3);
    backdrop-filter: blur(6px);
    color: var(--white);
    width: 45px;
    border-radius: 10px;
    padding: 5px 0;
    height: 40px;
    display: inline-block;
    text-align: center;
}

.video-modal-header {
    border-bottom: 0px;
}

.modal-video-content video {
    width: 100%;
    height: auto;
    border-radius: 16px;
    border: 0px;
    object-fit: contain;
}

.video-cross {
    position: absolute;
    top: 10px;
    right: 5px;
}

.body-radius {
    border-radius: 20px;
    border: none !important;
    background: transparent;
}

.video-cross-img {
    max-width: 75% !important;
    opacity: 1 !important;
    box-shadow: 0px 0px 2px 1px var(--black);
    border-radius: 20px;
    padding: 4px;
    background: var(--black);
}

.chanel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.see-all {
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.chanel-content {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 15px;
    border-radius: 20px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
    position: relative;
    border-radius: 24px 5px;
}

.like-img {
    position: absolute;
    right: 15px;
    top: 10px;
}

.chanel-content .chanl-img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
}

.chanel-text p {
    line-height: 10px;
}

.bite-img {
    border-radius: 20px;
}

.trending-text {
    position: absolute;
    border-radius: 13px 4px;
    background: var(--theme_pink);
    color: var(--white);
    padding: 6px 14px;
    left: 23px;
    top: 28px;
}

.stream-btn .btn-blue {
    padding: 16px 42px;
    border-radius: 6px 20px;
}

h4 {
    font-weight: 600;
}

.btn-white {
    padding: 16px 42px;
    color: var(--btn-color);
    background-color: var(--white);
    border: 1px solid #E3E0FE;
    border-radius: 6px 20px;
}

.btn-white:hover,
.btn-white:active {
    /* padding: 16px 42px; */
    color: var(--white);
    background-color: var(--theme_blue);
}


/* chanel start */
.chanel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.see-all {
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.bite-inner-img {
    width: 33px;
    height: 33px;
    border-radius: 50%;
}

.bite-relative {
    position: relative;
}

.bite-wrapper .common-btn {
    display: inline-flex;
    border-radius: 13px 4px;
    padding: 5px 40px;
    border: none;
    gap: 10px;
    align-items: center;
}

.btn-play {
    position: absolute;
    top: 50%;
    border: none;
    background-color: transparent;
    left: 15%;
    transform: translateY(-50%);
}

.bite-wrapper p {
    line-height: 20px;
    text-overflow: ellipsis;
}


.btn-play-icon {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    width: 60px;
}

.reduce-height {
    max-height: 130px;
    min-height: 130px;
}

.response-padding {
    padding: 15px 0;
}

.response-col {
    padding: 15px 10px;
}

.challenges {
    background: url('../../assets_new/background_channel.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}

.challenge-head h3 {
    line-height: 26px;
    color: #303030;
}

.challenge-img {
    position: relative;
    top: 35px;
}

.challenge-head p {
    color: #303030;
    line-height: 26px;
}

.video-upload {
    border-radius: 12px;
    width: 100%;
    height: 250px;
    border: 2px solid #8e8e8e;
}

.video-upload video {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
}

.playbtn-image {
    position: absolute;
    top: 45%;
    right: 45%;
}

.video-select {
    border: 2px dashed #303030;
    border-radius: 8px;
    background-color: #fff;
}

.video-file {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.video-upl-text {
    position: absolute;
    top: 45%;
    right: 35%;
    line-height: 26px;
    letter-spacing: -0.408px;
    color: #303030;
}

.custom-btn {
    width: 400px;
}

.Upload-text {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.408px;
    color: #303030;
}


@media ((min-width: 1200px) and (max-width: 1400px)) {
    .latest-video-info p {
        font-size: 18px;
    }

    .watch-btn {
        padding: 0 14px !important;
        gap: 5px;
        height: 35px !important;
        font-size: 14px !important;
    }

    .play-vid-btn img {
        width: 30px !important;
    }

    .like-btn {
        width: 35px;
        border-radius: 5px;
        padding: 3px 0;
        height: 30px;
    }

    .video-time {
        position: absolute;
        bottom: 10px;
        right: 20px;
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        line-height: 25px;
    }

}

@media screen and (max-width: 992px) {
    .challenges {
        /* background: url('../image/background_channel.png'); */
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
    }
}

@media screen and (max-width: 567px) {
    .custom-btn {
        width: 300px;
    }

}

@media screen and (max-width: 480px) {

    .custom-btn {
        width: 200px;
    }
}

@media screen and (max-width:1920px) {
    .bite .bite-img {
        max-width: 200px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    img.bite-img.reduce-height {
        max-height: 100px;
        min-height: 100px;
    }

    img.bite-img {
        max-height: 150px;
        min-height: 150px;
        height: 100%;
    }

}

@media screen and (max-width: 1600px) {
    .bite .bite-img {
        max-width: 150px;
        width: 100%;
        height: 150px;
        object-fit: cover;
    }

    .btn-play-icon {
        position: absolute;
        top: 50%;
        left: 20%;
        transform: translate(-50%, -50%);
        width: 40px;
    }

    img.bite-img {
        max-height: 120px;
        min-height: 120px;
    }

    .bite .bite-img {
        max-width: 120px;
    }

    .chanel-content {
        gap: 12px;
        padding: 10px;
    }

    .chanel-text {
        gap: 5px !important;
        align-items: flex-start !important;
    }

    .bite-wrapper .common-btn {
        padding: 5px 35px;
        font-size: 16px;
    }
}

@media screen and (max-width:1200px) {
    .chanel-content .chanl-img {
        width: 60px;
        height: 60px;
    }

    .bite .bite-wrapper {
        margin: 0;
    }

    .btn-play {

        left: 40%;
    }

    .bite-wrapper {
        margin: 0;
    }

    .btn-play-icon {
        left: 25%;
        width: 40px;
    }

    .video-time {
        right: 20px;
    }

}

@media screen and (max-width:991px) {
    .bite .row.gy-4 {
        --bs-gutter-y: 0 !important;
    }

    .play-icon {
        width: 90px;
        height: 90px;
        padding: 28px 33px;
        bottom: 30px;
        right: 0;
    }

    .response-padding {
        padding: 15px 0;
    }

    .btn-play-icon {
        left: 15%;
        width: 40px;
    }

    .bite-wrapper h4 {
        font-size: 20px;
    }

}

@media screen and (max-width:767px) {
    .bite-wrapper p {
        max-width: 160px;
    }
}

@media screen and (max-width:575px) {

    .btn-play {
        left: 15%;
    }

    .play-icon {
        width: 60px;
        height: 60px;
        bottom: 30px;
        right: 10px;
    }
}

@media screen and (max-width:480px) {
    .bite-wrapper p {
        max-width: 100%;
    }

    .btn-play-icon {
        left: 20%;
    }

    .video-time {
        right: 10px;
    }
}

@media screen and (max-width:420px) {
    .btn-play {
        top: 130px;
        left: 45%;
    }

    .watch-btn {
        font-size: 14px;
        padding: 0 14px !important;
        gap: 5px;
        height: 30px !important;
    }

    .watch-btn img {
        width: 10px;
    }

    .latest-video-info p {
        font-size: 14px;
    }

    .latest-video .d-flex {
        align-items: center;
    }

    .like-btn {
        width: 38px;
        padding: 3px 0;
        height: 35px;
    }

    .bite-wrapper h4 {
        font-size: 16px;
    }

    .chanel-text.mt-4 {
        margin-top: 0.5rem !important;
    }

    .bite .bite-img {
        max-width: 100px;
    }
}

@media screen and (max-width:300px) {
    .bite-wrapper h4 {
        font-size: 14px;
    }

    .bite-wrapper .common-btn {
        padding: 5px 25px;
        font-size: 16px;
    }
}

.challenge-pic-cus img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: contain;
    background-color: #fff;
    z-index: 2;
}

/* channel page */

.channel-detail-custom .channel-img {
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.channel-detail-custom .channels-box {
    background: rgba(253, 253, 254, 0.5);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
    border-radius: 28px;
    text-align: center;
    min-height: 200px;
    padding: 25px;
}

.channel-detail-custom .like-icon {
    position: absolute;
    top: 11px;
    right: 18px;
}

.channel-detail-custom .latest-series-name {
    display: grid;
    grid-template-columns: auto 120px;
    align-items: center;
    gap: 10px;
}

.channel-detail-custom .latest-series-name .video-tile p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: auto !important;
}

.channel-detail-custom .latest-vid-img {
    height: 300px;
    object-fit: cover;
}

.channel-detail-custom .like-fav-cus {
    position: absolute !important;
    bottom: 13px !important;
    right: 31px;
    background: rgba(188, 188, 188, 0.3);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    color: var(--white);
    width: 45px;
    border-radius: 10px;
    padding: 8px 0;
    height: 40px;
    display: inline-block;
    text-align: center;
}

.channel-detail-custom .featured-like-btn {
    position: absolute !important;
    bottom: 38px !important;
    right: 31px;
    background: rgba(188, 188, 188, 0.3);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    color: var(--white);
    width: 45px;
    border-radius: 10px;
    padding: 8px 0;
    height: 40px;
    display: inline-block;
    text-align: center;
}

/* 12-june-23 */
.series-like-btn {
    position: absolute;
    bottom: 15px;
    right: 29px;
    background: rgba(188, 188, 188, 0.3);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    color: var(--white);
    width: 45px;
    border-radius: 10px;
    padding: 8px 0;
    height: 40px;
    display: inline-block;
    text-align: center;
}
 .like-light {
    filter: brightness(53);
}
/* .channel-detail-custom .like-fav-cus img,
.channel-detail-custom .featured-like-btn img {
    filter: brightness(3.5);
} */
.fav-filter {
    position: absolute;
    right: 28px ;
    top: 25px;
}

.channel-detail-custom .latest-video-cus .common-btn {
    padding: 0px 0px !important;
}

@media (max-width:1600px) {
    /* .channel-detail-custom .latest-video-cus .common-btn {
        padding: 0px 22px !important;
    } */
}

@media screen and (max-width: 420px) {
    .channel-detail-custom .bite .bite-relative {
        width: auto;
    }
}

@media screen and (max-width: 575px) {
   
    .fav-filter {
        position: absolute;
        right: -6px;
        top: 34px;
    }
    .fav-filter-top{
        display: block !important;
    }
    .channel-detal-fav-icon{
       display: flex;
       justify-content:space-between;
       align-items: center;
    }
    .fav-filter-sm{
        display: none;
    }
}
@media screen and (max-width: 767px) {
.bite-img.reduce-height {
    max-width: initial !important;
}
}

.fav-filter-top{
    display: none;
}
.fav-filter-top .btn-filter {
    border: none;
}
@media (max-width: 340px){
.features-cnhl-detail .bite-img.reduce-height {
    max-width: initial !important;
}
.features-cnhl-detail .chanel-content {
    padding: 15px 10px 15px 10px;
}
}