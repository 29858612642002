.stream-btn .btn-blue {
    padding: 16px 42px;
    border-radius: 6px 20px;
}

h4 {
    font-weight: 600;
}

.btn-blue {
    padding: 16px 42px;
    border: 1px solid #E3E0FE;
    border-radius: 20px 6px;
}

.btn-white:hover,
.btn-white:active {
    /* padding: 16px 42px; */
    color: var(--white);
    background-color: var(--theme_blue);
}


/* video-bannr start */
.bgwhite {
    background: #FDFDFE;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
    border-radius: 42px;
    padding: 10px 10px;
    width: 290px;
}

.mvie-title {
    width: 50px;
    height: 50px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

.mv-title {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.movie-wrapper {
    padding: 40px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(29px);
}

.frame-video-img img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: unset;
}

.img-new {
    margin: 0px;
    padding: 0px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.epsoide-slider-wrapper {
    /* width: 267px;
    height: 300px; */
    border-radius: 13px !important;
}
.epsoide-slider-wrapper .movie-more {
    border-radius: 26px;
}
.epsiode-slider .owl-item.active {
    width: 267px !important;
    height: 300px;
}
.epsiode-slider .owl-stage {
    width: 100% !important;
}
/* video-bannr end */

.bg-pink-play{
    background: var(--theme_pink);
    border: 0;
    width: 100%;
    color: #fff;
    border-radius: 26px;
    padding: 10px;
    margin-top: 10px;
}
.bg-pink-play img{
  width: 20px;
  height: 20px;
}

@media screen and (max-width:1200px) {
    .movie-wrapper {
        padding: 20px 30px;
    }

    .bgwhite .gap-4 {
        gap: 1rem !important;
    }

    .bgwhite {
        width: 235px;
    }
}

@media screen and (max-width:991px) {

    .bgwhite .gap-4 {
        gap: 0.5rem !important;
    }

}

@media screen and (max-width:767px) {
    .bgwhite h4 {
        font-size: 18px !important;
    }

    .bgwhite {
        width: 200px;
    }

}