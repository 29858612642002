.custom-loading-color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    font-weight: bold;
    display: block;
}

.k-loading-text {
    margin-bottom: 20px;
}

/* .k-loading-image,
.k-loading-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
} */

.k-loading-image {
    /* border: 6px solid;
    border-top-color: #ca3392; */
    animation: spin 0.5 linear infinite;
}

.k-loading-color {
    background-color: #ca3392;
}

.loader-new {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(231, 230, 254, 0.2);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.custom-loading-color {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    color: rgb(89, 81, 229) !important;
    transform: translate(-50%, -50%) !important;
}

.k-loading-image::before,
.k-loading-image::after {
    content: "";
    border-width: 1px;
    border-width: clamp(0.015em, 1px, 1px);
    font-size: 4em;
}

.k-i-loading::before,
.k-loading-image::before {
    margin-top: -0.5em;
    margin-left: -0.5em;
    width: 1em;
    height: 1em;
    animation: k-loading-animation 0.1s linear infinite;
}

.k-i-loading::before,
.k-i-loading::after,
.k-loading-image::before,
.k-loading-image::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    content: "";
    box-sizing: inherit;
    border-radius: 50%;
    border-style: solid;
    border-color: #ca3392;
    border-top-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
}

.k-loading-image::before,
.k-loading-image::after {
    content: "";
    border-width: 1px;
    border-width: clamp(0.015em, 1px, 1px);
    font-size: 4em;
}

.k-i-loading::after,
.k-loading-image::after {
    margin-top: -0.25em;
    margin-left: -0.25em;
    width: 0.5em;
    height: 0.5em;
    animation: k-loading-animation reverse 0.1s linear infinite;
}

.k-i-loading::before,
.k-i-loading::after,
.k-loading-image::before,
.k-loading-image::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    content: "";
    box-sizing: inherit;
    border-radius: 50%;
    border-style: solid;
    border-color: #ca3392;
    border-top-color: #ca3392;
    border-bottom-color: transparent;
    background-color: transparent;
}

.loader-img {
    width: 12px;
    height: 12px;
    object-fit: cover;
}

/* .k-loading-image {
    z-index: 2;
    color: currentColor;
} */

/* .k-loading-mask,
.k-loading-image,
.k-loading-color {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
} */

.k-loading-image {
    /* border: 6px solid #ca3392;
    border-top-color: transparent; */
    animation: spin 5s linear infinite;
}

.k-loading-image,
.k-loading-color {
    /* width: 15px;
    height: 15px;
    border-radius: 50%; */
}

/* .k-loading-color {
    background-color: #ffffff;
    opacity: 0.3;
} */

.custom-loading-color.progress-bar-outer {
    width: 300px !important;
}
.custom-loading-color.progress-bar-outer .progress-bar {
    background-color: #ca3392;
}