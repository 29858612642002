.creator-dashboard {
    background: #fff;
    backdrop-filter: blur(6px);
    border-radius: 30px;
    padding: 40px 50px;
}
.large-text {
    font-size: 50px;
    font-weight: 700;
}
.small-text {
    font-size: 30px;
    font-weight: 700;
}
.buy-btn {
    background: rgb(202 51 146 / 10%);
    color: #CA3392;
    border: 0;
    border-radius: 10px;
    padding: 7px;
}
.subscription-fees {
    width: 90%;
}
.skip-subscrip{
 width: 10%;
}
/* table */
.subscription {
    margin: 0px;
    padding: 0px;
    border-radius: 20px;
    background-color: #ffffff;
    border: 2px solid #fae6f2;
}

.subscription>.table-responsive {
    height: auto;
    width: 100%;
}
.playhuman-btn {
    background-color: #fadaee;
    color: #CA3392;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 23.45px;
    letter-spacing: -0.435px;
    padding: 12px 25px;
    margin: 0px 0px;
}
.features {
    color: #737373;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 200px;
}
.subscription-table {
    background-color: #ffffff;
    border-radius: 20px;
    margin: 0px;
    padding: 0px;
    width: 100%;
}
.check-img {
    width: 20px;
    height: 20px;
}
@media screen and (max-width:700px) {
    .subscribers-table>.table-responsive>table>thead>tr>td:nth-child(3) p{
        width: 200px;
    }
    .subscribers-table>.table-responsive>table>thead>tr>td:first-child p{
        width: 200px;
    }
    
}
@media (max-width: 768px) {
    .modal-content {
        width: 100%;
        max-width: none;
        padding: 28px;
        margin: 10px;
    }
    .modal-schedule-width {
        max-width: 100%;
    }
}