.about-content {
    width: 85%;
  }
  .vision-head {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(217px);
    border-radius: 90px;
    padding: 60px 100px;
    border: 4px solid white;
  }
  
  .vision-content {
    width: 92%;
    margin: 0px auto;
  }
  .vision-head {
    /* background: url(../image/about_vision.png); */
    background-repeat: no-repeat;
    background-position: center;
  }
  .entertainment-content {
    padding-left: 135px;
  }
  .read-more-btn {
    padding: 16px 45px;
  }
  .join-play-btn {
    padding: 16px 21px;
  }

