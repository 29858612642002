 /* channel page */
 .favourite-cus .channel-img {
     filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
     width: 100px;
     height: 100px;
     border-radius: 50px;
     object-fit: contain;
     background-color: #fff;
     object-position: center;
     object-fit: cover !important;
     background-color: #fff !important;
 }

 .favourite-cus .channels-box {
     background: rgba(253, 253, 254, 0.5);
     box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
     border-radius: 28px;
     text-align: center;
     min-height: 200px;
     padding: 25px;
     object-fit: cover;
     background-color: #fff;
 }

 .favourite-cus .like-icon {
     position: absolute;
     top: 11px;
     right: 34px !important;

 }
 .like-icon-channel {
    position: absolute;
    top: 10px;
    right: 34px;
}

.favourite-cus .bite-img-fav {
    max-width: 100% !important;
    width: 148px !important;
    height: 162px;
    object-fit: cover;
    border-radius: 24px;
}
 .favourite-cus .bite-wrapper .common-btn {
     padding: 5px 20px;
 }

 .favourite-cus .chanel-content {
     gap: 26px;
 }

 .favourite-cus .chanel-content {
     background: #fff;
     border-radius: 24px 5px !important;
 }

 @media (min-width:768px) and (max-width:1500PX) {
     .favourite-cus .bite-img-fav {
         width: 136px !important;
         height: 127px;
     }
 }

 @media (min-width:1200px) and (max-width:1300PX) {
     .favourite-cus .bite-wrapper .common-btn {
         padding: 5px 11px !important;
     }

     .favourite-cus .chanel-content {
         gap: 15px !important;
     }

     .favourite-cus .bite-img-fav {
         width: 111px !important;
         height: 127px;
     }
 }

 @media (min-width:1000px) and (max-width:1440PX) {
     .favourite-cus .bite-wrapper .common-btn {
         padding: 5px 11px !important;
     }
 }

 .form-allchannel-search input {
    border: 1px solid #E3E0FE !important;
    border-radius: 48px;
    height: 60px;
    padding-left: 61px;
    width: 100%;
    position: relative;
}
.form-allchannel-search .filter-search {
    position: absolute;
    top: 1px;
    right: 18px;
    border: none;
    height: 100%;
    padding: 5px 13px;
    width: auto;
    background: transparent;
}
.form-allchannel-search .icon-search {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 13px;
    border: none;
    height: 100%;
    padding: 5px 13px;
    width: auto;
    background: transparent;
}

.home-price-tag{
    right: 0px !important;
    top: 0px !important;
    border-radius: 0px 0px 0px 16px !important;
    bottom: unset !important;
    padding: 8px 20px !important;
}
.buy-feature {
    position: absolute;
    right: 73px;
    bottom: 10px;
    font-size: 24px !important;
    padding: 17px 38px!important;
}