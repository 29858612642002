header {
    background-color: var(--white);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    /* backdrop-filter: blur(50px); */
    padding: 12px 0;
  }
  
  .navbar-toggler,
  .navbar-toggler:focus {
    border: none;
    background-color: transparent;
    outline: none;
    box-shadow: none;
  }
  
  .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: var(--theme_blue);
  }
  .navbar-nav .nav-link.active,
  .navbar-nav .show > .nav-lin {
    color: var(--theme_pink);
  }
  
  .navbar-brand img {
    width: 100%;
    max-width: 250px;
  }
  
  .nav-link:focus,
  .nav-link:hover {
    color: var(--theme_pink);
  }