.bg-body {
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}

/*header mein*/
.common-btn.header-btn {
  padding: 15px 50px;
}

a:hover {
  text-decoration: none;
}

.banner-img-new {
  display: none;
}

/* .popular-channel .popular-div:hover {
  background-color: linear-gradient(left to right #CCBAFB, #8E9AFF, #B48AFD) !important;
} */

/*heder*/

/*banner starts*/

/*banner ends*/

/* streaming-wrapper start */

/* streaming-wrapper end */

/* entertaiment start*/

/* entertaiment end */

/* comunnity-wraper */



.owl-carousel .owl-item .test-svg img {
  width: 45px;
}

.testimonial .owl-nav button.owl-prev span,
.testimonial button.owl-next span {
  padding: 10px 36px !important;
}

.testimonial button.owl-next span {
  margin-left: 100px !important;
}

/*  responsive*/
@media screen and (max-width: 1600px) {
  h1 {
    font-size: 45px;
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  .py-100 {
    padding: 90px 0;
  }

  .py-60 {
    padding: 40px 0;
  }

  .font-120 {
    font-size: 80px;
  }

  .f-70 {
    font-size: 50px;
  }

  .streaming-wrapper img {
    max-width: 50%;
  }

  .navbar-brand img {
    max-width: 165px;
  }

  .common-btn {
    padding: 10px 35px;
  }
  .grey-btn{
    padding: 10px 35px;
  }

  .entertaiment-wrapper .owl-carousel .owl-item .img-absolute img {
    width: 90px !important;
  }

  .img-absolute {
    right: 100px;
    bottom: 10px;
  }

  /* .popular-channel .popular-box {
    width: 100% !important;
  } */

  .testimonial .owl-carousel .owl-item .test-svg img {
    width: 50px !important;
  }

  .endsecton .navbar-brand img {
    max-width: 250px;
  }

  .social-icon img {
    width: 40px;
  }

  .testimonial .owl-nav button.owl-prev,
  .testimonial button.owl-next {
    width: 48%;
  }

  .testimonial .owl-nav button.owl-prev span,
  .testimonial button.owl-next span {
    bottom: -210px !important;
    left: 50% !important;
  }

  .font-120 {
    font-size: 80px;
  }

  .font-70 {
    font-size: 50px;
  }

  .header-btn.common-btn {
    padding: 12px 36px;
    height: 50px;
    font-size: 16px;
  }
}



@media screen and (max-width: 1200px) {
  h1.hero-text {
    font-size: 40px;
    width: 550px !important;
  }

  .hero-text span.pink-text.font-120 {
    font-size: 60px !important;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 25px;
  }

  .banner-wraper .btn-blue {
    padding: 13px 15px !important;
  }

  .down-img {
    padding: 7px 15px;
  }

  .common-btn {
    font-size: 16px;
  }
  .grey-btn{
    font-size: 16px;
  }

  .entertaiment-wrapper .owl-carousel .owl-item .img-absolute img {
    width: 60px !important;
  }

  .img-absolute {
    right: 70px;
  }

  .entertaiment-wrapper .owl-dots button.owl-dot {
    width: 30px;
    height: 10px;
  }

  .testimonial .test-svg h2.py-3 {
    padding: 10px 0 !important;
  }

  .testimonial .owl-nav button.owl-prev span,
  .testimonial button.owl-next span {
    line-height: 20px;
    padding: 10px 25px !important;
  }

  .testimonial button.owl-next span {
    margin-left: 80px;
  }

  .testimonial .owl-nav button.owl-prev span,
  .testimonial button.owl-next span {
    bottom: -180px !important;
  }

  .testimonial-client {
    margin-top: 15px !important;
  }

  .testimonial .wrapper-border {
    padding-bottom: 70px !important;
  }

  .btn-blue {
    padding: 15px 33px !important;
  }

  .common-btn {
    padding: 15px 40px !important;
    font-size: 16px !important;
  }
  .grey-btn{
    padding: 15px 40px !important;
    font-size: 16px !important;
  }

  .popular-channel .popular-box {
    width: 80% !important;
  }

  .banner-wraper .btn-blue {
    padding: 15px 15px !important;
    height: 55px;
  }

  .app-btn.common-btn {
    padding: 4px 8px 2px 4px !important;
    font-size: 16px !important;
    height: 55px;
  }
}
@media screen and (max-width: 991px) {
  .faq-bottom {
    padding-left: 25px;
    text-align: left !important;
    padding-bottom: 22px !important;
}
  .entertaiment-wrapper .owl-nav button {
    position: absolute;
    top: 50%;
    display: none;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.38) !important;
  }

  .common-btn {
    border-radius: 25px 5px;
  }
  .grey-btn{
    border-radius: 25px 5px;
  }

  .banner-conent {
    width: 100%;
    text-align: center;
  }

  h1.hero-text {
    font-size: 50px;
    width: 620px !important;
  }

  h3.greybanner {
    font-size: 30px;
  }

  .banner-conent .banner-img {
    width: 70%;
    position: relative;
    left: 0;
    display: inline-flex;
    float: right;
    top: 40px;
  }

  .banner-wraper .d-flex {
    justify-content: center;
  }

  .streaming-wrapper img {
    max-width: 80%;
  }

  .img-absolute {
    right: 100px;
    bottom: 40px;
  }

  .entertaiment-wrapper .owl-carousel .owl-item .img-absolute img {
    width: 100px !important;
  }

  .testimonial .owl-carousel .owl-item .test-client {
    width: 60% !important;
    margin: auto;
  }

  .test-svg {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .testimonial .owl-nav button.owl-prev span,
  .testimonial button.owl-next span {
    bottom: -360px !important;
  }

  .banner-conent.banner-text {
    padding-left: 0;
  }

  .entertaiment-text,
  .join-text {
    text-align: center;
  }

  .entertaiment-wrapper .d-flex,
  .join-comunity .d-flex {
    justify-content: center;
  }

  .banner-wraper .common-btn {
    padding: 4px 14px 4px 0 !important;
  }

  .banner-wraper .common-btn.banner-join {
    padding: 14px 20px !important;
  }

  .footer-ul {
    justify-content: flex-start !important;
  }

  ul.navbar-nav>div.ms-3 {
    margin-left: 0 !important;
  }

  .banner-img-new {
    display: inline-block;
    text-align: center;
    width: 80%;
    margin-top: 50px;
  }

  .banner-img {
    display: none;
  }

  .banner-img {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 35px;
  }

  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 22px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  .py-80 {
    padding: 50px 0;
  }

  .py-60 {
    padding: 30px 0;
  }

  .banner-text h1 {
    max-width: 480px;
    margin: auto;
    font-size: 36px;
  }

  h3.greybanner {
    font-size: 25px;
  }

  .hero-text span.pink-text.font-120 {
    font-size: 50px !important;
  }

  .greybanner {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .wrapper-border,
  .comunnity-wraper {
    border-radius: 40px;
  }

  .streaming-wrapper h2 {
    font-size: 28px;
    max-width: 300px;
    margin: auto;
  }

  .entertaiment-text .mt-5 {
    margin-top: 1rem !important;
  }

  .testimonial .owl-nav button.owl-prev span,
  .testimonial button.owl-next span {
    bottom: -330px !important;
  }

  .font-120 {
    font-size: 50px;
  }

  .img-absolute {
    right: 90px;
    bottom: 10px;
  }

  .entertaiment-wrapper .owl-carousel .owl-item .img-absolute img {
    width: 80px !important;
  }

  .popular-channel h2 .mb .mb-5 {
    margin-bottom: 2rem !important;
  }
}

@media screen and (max-width: 767px) {
  .join-btn .common-btn {
    padding: 15px 20px !important;
    font-size: 14px !important;
  }
}

@media screen and (max-width: 480px) {
  .entertaiment-wrapper .owl-carousel .owl-item .img-absolute img {
    width: 53px !important;
  }

  .img-absolute {
    right: 60px;
    bottom: 0px;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 25px;
  }

  h3 {
    font-size: 20px;
  }

  .test-svg h2 {
    font-size: 20px;
  }

  .test-svg h3 {
    font-size: 18px;
    padding: 0 !important;
  }

  .test-svg h4 {
    font-size: 18px;
  }

  .testimonial .owl-nav button.owl-prev span,
  .testimonial button.owl-next span {
    bottom: -300px !important;
  }

  .common-btn {
    font-size: 14px;
  }
  .grey-btn{
    font-size: 14px;
  }

  .testimonial .owl-nav button.owl-prev span,
  .testimonial button.owl-next span {
    bottom: -275px !important;
  }

  .banner-text h1 {
    max-width: 350px;
    margin: auto;
    font-size: 32px;
  }

  .banner-conent .banner-img {
    width: 90%;
  }

  .entertaiment-text .common-btn,
  .comunnity-wraper .common-btn {
    padding: 16px;
  }

  .test-svg h2 {
    font-size: 20px;
    line-height: 35px;
  }

  .entertaiment-text {
    margin-top: 20px;
  }

  h3.greybanner {
    font-size: 20px;
  }
}

@media screen and (max-width: 575px) {
  .down-img {
    padding: 5px 10px;
  }

  .comunnity-wraper .ps-5,
  .comunnity-wraper .ps-3 {
    padding: 0 !important;
  }

  #entertainment .owl-dots .owl-nav .owl-prev span,
  #entertainment .owl-dots .owl-nav .owl-next span {
    display: none;
  }

  .app-btn.common-btn {
    height: 100%;
  }

  .banner-wraper .btn-blue {
    height: 100%;
  }

  .down-img {
    border-radius: 27px 4px 18px 5px;
  }

  .get-in-form {
    width: 80%;
  }
}

@media screen and (max-width: 420px) {

  .testimonial .owl-nav button.owl-prev span,
  .testimonial button.owl-next span {
    left: 20% !important;
  }

  .entertaiment-text .common-btn,
  .comunnity-wraper .common-btn {
    padding: 16px 10px;
  }

  .banner-text .gap-3 {
    gap: 10px !important;
  }

  .down-img {
    padding: 4px 7px;
  }

  .owl-carousel .owl-item .img-absolute img {
    width: 50px !important;
  }

  .img-absolute {
    right: 40px;
  }

  .owl-dots {
    padding: 0;
  }
  .accordion-help .accordion-item {
    padding: 0px  10px !important;
}
}

@media screen and (max-width: 380px) {
  .banner-text h1 {
    font-size: 22px;
    max-width: 245px;
  }

  .streaming-wrapper p {
    max-width: 100%;
    margin: auto;
  }

  .hero-text span.pink-text.font-120 {
    font-size: 43px !important;
  }

  .banner-wraper .common-btn.banner-join {
    margin-left: 22px;
  }

  h3.greybanner {
    font-size: 17px;
    max-width: 280px;
    margin: auto;
  }

  .hero-text span.pink-text.font-120 {
    font-size: 37px !important;
  }
}
@media screen and (max-width: 280px) {
  .join-btn .common-btn {
    width: 100% !important;
  }

  .test-svg {
    padding-left: 50px !important;
    padding-right: 10px !important;
  }

  .testimonial .owl-nav button.owl-prev span,
  .testimonial button.owl-next span {
    bottom: -255px !important;
    left: 20% !important;
  }

}

/* help page */
.faq-bottom {
  padding-bottom: 60px;
}
.accordion-help .accordion-button:focus {
  box-shadow: none;
  border-bottom: 1px solid  rgba(0,0,0,.125);
}
.accordion-help .accordion-button:not(.collapsed) {
  background-color: #fff;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
  color: #444444 !important ;
}
.accordion-help .accordion-button {
  font-size: 18px;
  line-height: 24px;
}
.accordion-help .accordion-button:not(.collapsed)::after {
  filter: brightness(0.5);
}
.accordion-help .accordion-item {
  margin-bottom: 14px;
  border-radius: 8px;
  padding: 0px 28px;
  border-bottom:0;

}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 8px 8px 0px 0px;
}

@media (max-width:320px){
   .navbar-collapse .common-btn {
    padding: 13px 28px !important;
}
}
.navbar-toggler-icon {
  height: auto;
}
