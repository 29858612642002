.challenges {
  background: url('../../../../assets_new/background_channel.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #fff;
}

.challenge-head h3 {
  line-height: 26px;
  color: #303030;
}

.challenge-img {
  position: relative;
  top: 35px;
}

.challenge-head p {
  color: #303030;
  line-height: 26px;
}
.border20{
  border-radius: 20px;
}
.video-upload {
  border-radius: 12px;
  width: 100%;
  height: 250px;
  border: 2px solid #8e8e8e;
}

.video-upload video {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
  background-color: #fff;
}

.playbtn-image {
  position: absolute;
  top: 45%;
  right: 45%;
}

.video-select {
  border: 2px dashed #303030;
  border-radius: 8px;
  background-color: #fff;
}

.video-file {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.video-upl-text {
  position: absolute;
  top: 45%;
  right: 35%;
  line-height: 26px;
  letter-spacing: -0.408px;
  color: #303030;
}

.custom-btn {
  width: 400px;
}

.Upload-text {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.408px;
  color: #303030;
}

/* video-modal-css  */
.video-modal-header {
  border-bottom: 0px;
}

.modal-video-content video {
  width: auto;
  height: auto;
  border-radius: 16px;
  object-fit: contain;
  border: 3px solid #8e8e8e;
  max-width: 100%;
  max-height: 600px;
}

.video-cross {
  position: absolute;
  top: 10px;
  right: 5px;
}

.body-radius {
  border-radius: 20px;
  border: none !important;
  background: transparent;
  width: auto;
}

.video-cross-img {
  max-width: 75% !important;
  opacity: 1 !important;
  box-shadow: 0px 0px 2px 1px var(--black);
  border-radius: 20px;
  padding: 4px;
  background: var(--black);
}

.modal-video-content {
  border-radius: 20px;
}

@media screen and (max-width: 992px) {
  .challenges {
    background: url('../../../../../public/assets/images/allChallanges/challenges_back.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    background-color: #fff;
  }
}

@media screen and (max-width: 567px) {
  .custom-btn {
    width: 300px;
  }

}

@media screen and (max-width: 480px) {
  .video-upl-text {
    right: 30%;
  }

  .custom-btn {
    width: 200px;
  }
}

.challenge-upload-custom .challenge-img {
  border-radius: 50%;
  width: 147px;
  height: 147px;
  object-fit: cover;
}

