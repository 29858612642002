 .login-wrapper {
     display: flex;
     justify-content: center;
     margin: auto;
     align-items: center;
     height: 100vh;
     background-repeat: no-repeat;
     background-size: cover;
     position: relative;
 }

 .signup-form .list-styled li a {
     color: var(--gray-text);
     font-size: 30px;
     line-height: 36px;
 }

 .signup-form .list-styled li.active a {
     color: var(--theme_blue);
 }

 .signup-form .login-width {
     /* background-image: url("../../../public/images/signin/sign-bg.png"); */
     background-repeat: no-repeat;
 }

 .signup-form .sign-form {
     background: var(--white);
     box-shadow: 4px 4px 100px rgba(0, 0, 0, 0.05);
     padding: 56px 36px 0;
 }

 .signup-form .input-login {
     height: 54px;
     border: 1px solid #D1D1D1 !important;
     color: var(--gray-text);
 }

 .signup-form .tab-content {
     padding: 24px 0 26px;
 }

 .signup-form .list-styled li.active {
     border-bottom: 5px solid #CA3392;
     border-radius: 4px 4px;
 }

 .signup-form .forgot-btn a {
     color: var(--theme_blue);
 }

 .signup-form .social-link span {
     display: block;
 }

 .signup-form .or-bg {
     background-repeat: no-repeat;
     background-position: center;
 }

 .signup-form .eye-closed {
     position: absolute;
     right: 15px;
     top: 15px;
 }

 .signup-form .or-bg span {
     background: #fff;
     width: 42px;
     padding: 0px 7px;
     font-size: 25px;
     line-height: 29px;
     color: #323232;
 }

 .signup-form .right-login {
     width: 100% !important;
     height: 100% !important;
 }

 .signup-form .social-link {
     gap: 30px;
 }

 .intl-tel-input,
 .iti {
     width: 100%;
 }

 .iti__flag.iti__ca,
 .iti__flag-box,
 .iti__flag.iti__in,
 .iti__flag {
     display: none !important;
 }

 .iti--allow-dropdown .iti__flag-container .iti__selected-flag {
     background-color: transparent !important;

 }

 .iti--allow-dropdown .iti__flag-container .iti__selected-flag:after {
     background-color: transparent !important;
     position: relative;
     content: "";
     border-right: 1px solid #D1D1D1 !important;
     height: 50px;
     left: 5px;
 }

 .signup-form .sign-form:before {
     content: "";
     position: absolute;
     background-image: url("./../../../public/assets/images/signin/sign-circle.png");
     background-repeat: no-repeat;
     transform: rotate(308deg);
     width: 259px;
     height: 143px;
     z-index: 9999;
     background-size: contain;
     top: -112px;
     left: -116px;
     filter: blur(13px);
 }

 .signup-form .cloud-sign {
     position: absolute;
     bottom: -65px;
     right: -94px;
     width: 358px;
 }

 .signup-form .square-box {
     top: -68px;
     position: absolute;
     right: 44px;
     width: 159px;
 }

 .signup-form .vector-sign {
     position: absolute;
     top: 0;
     right: -115px;
 }

 .signup-form .circle-sign {
     position: absolute;
     top: 128px;
     width: 57px;
     right: -38px;
 }

 .signup-form .loader {
     width: 100px;
     position: absolute;
     z-index: 2;
     top: 45vh;
     left: 100vh;
     z-index: 99999 !important;
 }

 .err_msg {
     color: red;
     font-size: 14px;
 }

 /* responsive login */
 @media (min-width: 993px) and (max-width: 1400px) {
     .signup-form .list-styled li a {
         font-size: 24px !important;
     }

     .signup-form .channels-box .channel-text h4 {
         font-size: 20px !important;
     }
 }

 @media (min-width: 1024px)and(max-width: 1360px) {
     .signup-form .channels-box {
         min-height: 250px;
     }

     .signup-form .channels-box .channel-text h4 {
         font-size: 20px !important;
     }
 }

 @media only screen and (max-width: 992px) and (min-width: 1400px) {
     .signup-form .channels-box .channel-text h4 {
         font-size: 20px !important;
     }
 }

 @media (max-width: 1024px) {
     .login-wrapper .signup-form {
         height: auto;
     }

     .signup-form .cloud-sign,
     .square-box,
     .vector-sign,
     .circle-sign,
     .sign-form:before {
         display: none;
     }
 }

 @media (max-width: 767px) {
     .signup-form .list-styled li a {
         font-size: 21px !important;
     }

     .signup-form .login-wrapper .tab-content {
         padding: 37px 0 34px;
     }

     .signup-form .sign-form:before {
         display: none;
     }

     .signup-form .or-bg span {
         font-size: 19px;
     }
 }

 @media (max-width: 375px) {

     /* .signup-form .social-link {
        display: initial !important;
    } */
     .signup-form.common-btn {
         padding: 18px 50px;
     }

     .signup-form .or-bg span {
         font-size: 19px;
     }
 }

 @media (min-width:992px) and (max-width:1700px) {
     .signup-form .square-box {
         top: -34px;
         position: absolute;
         right: 45px;
         width: 80px;
     }

     .signup-form .right-login {
         object-fit: cover;
     }

     .signup-form .cloud-sign {
         position: absolute;
         bottom: 0px;
         right: -73px;
         width: 193px;
     }
 }



 @media (max-width: 991px) {
     .login-wrapper {
         height: 100vh;
         padding: 45px 0;
     }
     .verify-modal-box .reset-otp {
        width: 30px !important;
        height: 30px;
    }
 }

 .logo-sign {
     margin: 10px 0 32px;
 }

 .react-tel-input .form-control {
     width: 100% !important;
     height: 54px;
     box-shadow: none !important;
     border: 1px solid #D1D1D1 !important;
 }
 .radio-login {
    accent-color: #ca3392;
    margin-right: 5px;
}
.creat-label label {
    font-size: 18px;
    font-weight: 500;
}
  input[type="radio"]:checked+span{
    color: #ca3392;
  }