.live-video {
    width: 100%;
    height: calc(100vh - 300px);
    position: relative;

}

.miccameramain {
    position: absolute;
    top: 62px;
    z-index: 99;
    right: 12px;
}

.micmain {
    width: 75px;
    height: 75px;
    background: #ffffff33;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

.endbutton {
    position: absolute;
    bottom: 44px;
    z-index: 99;
    left: 0;
    right: 0;
    text-align: center;
}

.endinnerbutton {
    border-radius: 30px 10px;
    border: 0;
    background: var(--theme_pink);
    color: #fff;
    height: 61px;
    width: 331px;
    font-size: 20px;
    font-weight: 600;
}

.livetime-button {
    position: absolute;
    top: 44px;
    z-index: 99;
    left: 37px;
    background: var(--theme_blue);
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border-radius: 5px;
    height: 48px;
    padding: 12px 20px;
}

.live-video:nth-child(4) {
    background-color: transparent !important;
}

.mutedvideo p {
    color: #07143A;
    font-family: Gilroy;
    font-size: 52.241px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.522px;
    text-transform: uppercase;
}

.mutedvideo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 100%;
    background-image: url("../../../public/assets/images/creater-dashboard/circleLive.svg");
    background-repeat: no-repeat;
    background-position: center;

}

.audiomuted {
    display: flex;
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 44%;
    right: 0;
    margin: auto;
    text-align: center;
}

.audiomuted p {
    font-size: 17px;
    background: #e0e0d2;
    padding: 1px 20px;
    border-radius: 5px;
    font-weight: 500;
}

.bothmutedstyle {
    top: 70%;
}

.live-name-profile img {
    width: 24px;
    height: 24px;
    border-radius: 50px;
    object-fit: cover;
    border: 1px solid #CA3392;
}

.live-img-btn {
    position: absolute;
    top: 24px;
    right: 28px;
    background: #fff;
    line-height: 0;
    padding: 0px 18px;
    border-radius: 10px;
}
.live-img-btn-below {
    position: absolute;
    top: 36px;
    left: 0;
    background: #ca33921f;
    line-height: 0;
    padding: 0px 18px;
    border-radius: 10px;
}
.liveiconlive {
    background: #ca339229;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
}
.chat-listing {
    display: grid;
    align-items: start;
    grid-template-columns: 45px auto 20px;
    gap: 12px;
}
.profile-chat img {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    border: 2px solid #ca3392;
}
.chat-right {
    border-radius: 30px;
    background: #FFF;
    height: 100%;

}
.chat-message {
    padding: 10px 27px 27px;
    height: 600px;
    overflow-y: auto;
}
.new-chat-message {
    height: 710px;
    overflow-y: auto;
}
.chat-heading {
    border-radius: 30px 30px 0px 0px;
    background: #F0F0F0;
    padding: 18px 27px;
}
.chat-bottom .profile-bottom {
    width: 54px;
    height: 54px;
    border-radius: 50px;
    object-fit: cover;
    border: 1px solid #07143A;
}
.chat-bottom {
    border-radius: 50px;
    background: #FFF;
    box-shadow: 4px 4px 100px 0px rgba(0, 0, 0, 0.10);
    height: 70px;
    display: flex;
    align-items: center;
    position: absolute;
    width: 92%;
    bottom: 27px;
    padding: 18px;
    gap: 11px;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
}
.chat-bottom input {
    font-size: 19px;
}
.para-chat{
    color:#5F5F5F;
}
.chat-time{
    color: #757D7E;
}
.userchat-message{
    height: 610px;
    overflow-y: auto;
}
.userchat-message .message-view{
    height: 100%;
}
.user-sidetext {
    min-width: 60px;
}

.user-side-day {
    grid-column: 2;
    text-align: end;
    font-size: 12px;
    margin-bottom: 0px;
    line-height: 14px;
}
.user-side-comment{
   line-height: 22px; 
}

.user-comments{
    line-height: 20px !important;
}
.recorded-message .get-msg {
    height: 84%;
    overflow-y: scroll;
}
.startlive-container{
    height: 86%;
    overflow-y: auto;
}
@media screen and (max-width:1600px) {
    .new-chat-message {
        height: 535px;
        overflow-y: auto;
    }
    .userchat-message{
        height: 450px;
        overflow-y: auto;
    }
    .recorded-message .get-msg {
        height: 82%;
        overflow-y: scroll;
    }
    .startlive-container{
        height: 82%;
        overflow-y: auto;
    }
}
@media screen and (max-width:1440px) {
    .live-video {
        width: 96%;        
    }
    .new-chat-message {
        height: 500px;
        overflow-y: auto;
    }
    .userchat-message {
        height: 430px;
        overflow-y: auto;
    }
}
@media screen and (max-width:991px) {
    .live-video {
        width: 100%;        
    }
    .new-chat-message .chat-wrapper:nth-last-child() {
        margin-bottom: 80px !important;
    }
    .live-chat-bottom{
        width: 98%;
    }
    .userchat-container {
        height: 82%;
        overflow-y: auto;
    }
} 

@media screen and (max-width:768px) {
    .startlive-container{
        height: 80%;
        overflow-y: auto;
    }
}

@media screen and (max-width:567px) {
    .userchat-container {
        height: 78%;
        overflow-y: auto;
    }
}

.custom-select {
    position: relative;
    width: 150px;
  }
  .arrow-down {
    position: absolute;
    top: 0;
    right: 0;
}
.selected-option {
    padding: 10px;
    cursor: pointer;
    height: 51px;
    border-radius: 5px;
    border: 1px solid #FFF;
    background: #F8F8FF;
    color: #303030 !important;
    font-size: 18px ;
    font-weight: 500 !important;
    letter-spacing: -0.247px;
}
  
  .options {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    z-index: 1000;
  }
  
  .option {
    padding: 10px;
    cursor: pointer;
  }
  
  .option:hover {
    background-color: #f0f0f0;
  }
  
  .active-option {
    font-size: 18px;
    font-weight: 500;
}