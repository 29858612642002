.about-us-back {
    /* background: url(../image/About_us_background.png); */
    background-repeat: no-repeat;
    background-position-y: -100px;
    width: 100%;
    background-size: cover;
  }

  /* responsive-css */
  @media screen and (max-width: 1200px) {
    .about-content {
      width: 95%;
    }
    .vision-head {
      padding: 50px 80px;
    }
    .entertainment-content {
      padding-left: 65px;
    }
  }
  
  @media screen and (max-width: 991px) {
    .entertainment-content {
      padding-left: 0;
  }
    .about-banner,
    .entertainment-banner {
      text-align: center;
    }
    .about-content {
      width: 100%;
    }
    .about-us-back {
      background-position-y: unset;
    }
    .vision-head {
      padding: 30px 50px;
    }
    .vision-img {
      margin: 30px 0px;
    }
    .vision-content {
      width: 100%;
      margin: 0px auto;
    }
    .entertainment-content {
      padding-left: unset;
    }
   
  }
  @media screen and (max-width: 767px) {
    .vision-head {
      padding: 20px 30px;
  }
  
  }
  @media screen and (max-width: 567px) {
    .vision-head {
      padding: 10px 20px;
    }
  }
  @media screen and (max-width: 567px) {
  .join-play-btn{
    margin-bottom: 10px;
    margin-left: 0px !important;
  }
  }
  @media (min-width:1201px) and (max-width:1790px){
    .entertainment-content {
      padding-left: 80px;
  }
  .btn-blue {
    padding: 15px 40px;
  }
  .abouts-ltd-btn .common-btn {
    padding:15px 40px;
  }
  }

  @media (max-width:400px){
    .abouts-ltd-btn .join-play-btn {
      margin-right: 0 !important;
  }
  }
  .abouts-ltd-btn .join-play-btn {
    margin-right: 16px;
}