 /* channel page */
 .favchannel-main-cus .channel-img {
     filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
     width: 100px;
     height: 100px;
     border-radius: 50px;
 }

 .favchannel-main-cus .channels-box {
     background: rgba(253, 253, 254, 0.5);
     box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
     border-radius: 28px;
     text-align: center;
     min-height: 200px;
     padding: 25px;
 }

 .favchannel-main-cus .like-icon {
     position: absolute;
     top: 11px;
     right: 32px;
 }

 .favchannel-main-cus .latest-series-name {
     display: grid;
     grid-template-columns: auto 120px;
     align-items: center;
     gap: 10px;
 }

 .favchannel-main-cus .latest-series-name .video-tile p {
     overflow: hidden;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     -webkit-box-orient: vertical;
     height: auto !important;
 }

 .favchannel-main-cus .latest-vid-img {
     height: 300px;
     object-fit: cover;
 }

 .favchannel-main-cus .like-fav-cus {
     position: absolute !important;
     bottom: 13px !important;
     right: 31px;
     background: rgba(188, 188, 188, 0.3);
     -webkit-backdrop-filter: blur(6px);
     backdrop-filter: blur(6px);
     color: var(--white);
     width: 45px;
     border-radius: 10px;
     padding: 5px 0;
     height: 40px;
     display: inline-block;
     text-align: center;
 }

 .favchannel-main-cus .like-fav-cus .fav-like-series {
     filter: initial !important;
 }

 .favchannel-main-cus .like-fav-cus img {
     filter: brightness(3.5);
 }

 .favchannel-main-cus .latest-video-cus .common-btn {
     padding: 0px 0px !important;
 }

 .editprofile-modal .user-profile-upload {
     max-width: 200px;
     margin: 0 auto;
     display: block;
     background: #fff;
     width: 134px;
     height: 134px;
     border-radius: 50%;
     border: 2px solid var(--theme_pink);
 }

 .editprofile-modal .profile-upload .profile-edit {
     width: 130px;
     height: 130px;
     border-radius: 50%;
     margin: auto;
     transform: translate(-50%, -50%);
     left: 50%;
     top: 50%;
     position: absolute;
     object-fit: cover;
 }

 .editprofile-modal .profile-upload input.profile-edit-file {
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     height: 100%;
     width: 100%;
     opacity: 0;
 }

 .editprofile-modal .profile-mail {
     line-height: 19px !important;
     color: #D2D2D2 !important;
 }

 .editprofile-modal .profile-edit-icon {
     position: absolute;
     max-width: 30%;
     height: auto;
     bottom: 2%;
     right: 8%;
 }

 .close-btn {
     position: absolute;
     top: 13px;
     right: 23px;
     width: 17px;
     height: 17px;
 }

 .team-mail {
     font-style: italic;
     font-size: 20px;
     color: #747474;
     font-weight: 500;
 }

 @media (max-width:446px) {
     .favourite-cus .chanel-content {
         gap: 9px;
     }

     .favourite-cus .bite-img-fav {
         max-width: 50% !important;
         width: auto !important;
         height: 150px;
         object-fit: cover;
         border-radius: 24px;
     }
 }

 @media (max-width:340px) {
     .favourite-cus .bite-img-fav {
         max-width: 50% !important;
     }

     .favchannel-main-cus .chanel-content {
         display: block;
     }

     .favchannel-main-cus .common-btn {
         padding: 5px 18px !important;
         font-size: 14px !important;
     }
 }

 @media (max-width:320px) {
     .favourite-cus .bite-img-fav {
         max-width: 100% !important;
     }
    }

    .user {
        border-radius: 25px;
        background-color: white;
    }
    
    .switch {
        position: relative;
        display: inline-block;
        width: 55px;
        height: 29px;
    }
    
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }
    
    .slider:before {
        position: absolute;
        content: "";
        height: 21px;
        width: 22px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    
    input:checked+.slider {
        background-color: #CA3392;
    }
    
    input:focus+.slider {
        box-shadow: 0 0 1px #2196F3;
    }
    
    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    
    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }
    
    .slider.round:before {
        border-radius: 50%;
    }
    
    .btn {
        background-color: #CA3392;
        border-radius: 20px 5px 20px 5px;
        width: auto;
    }
    
    .user-info>a {
        color: #CA3392;
        font-size: 23px;
        font-weight: 500;
        word-spacing: 3px;
        letter-spacing: 0.5px;
    
    
    }
    
    .user-info>h5 {
        font-style: italic;
        font-weight: 100;
    }
    