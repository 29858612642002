  .login-wrapper {
      display: flex;
      justify-content: center;
      margin: auto;
      align-items: center;
      height: 100vh;

      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
  }

  .signin-form .list-styled li a {
      color: var(--gray-text);
      font-size: 30px;
      line-height: 36px;
  }

  .signin-form .list-styled li.active a {
      color: var(--theme_blue);
  }

  .signin-form .login-width {
      /* background-image: url("../../../public/images/signin/sign-bg.png"); */
      background-repeat: no-repeat;
  }

  .signin-form .sign-form {
      background: var(--white);
      box-shadow: 4px 4px 100px rgba(0, 0, 0, 0.05);
      padding: 56px 36px 0;
  }

  .signin-form .input-login {
      height: 54px;
      border: 1px solid #D1D1D1 !important;
      color: #000;
  }

  .signin-form .tab-content {
      padding: 35px 0 26px;
  }

  .signin-form .social-link {
      gap: 30px;
  }

  .signin-form .list-styled li.active {
      border-bottom: 5px solid #CA3392;
      border-radius: 4px 4px;
  }

  .signin-form .forgot-btn a {
      color: var(--theme_blue) !important;
      cursor: pointer !important;
  }

  .signin-form .social-link span {
      display: block;
  }

  .signin-form .or-bg {
      background-repeat: no-repeat;
      background-position: center;
  }

  .signin-form .eye-closed {
      position: absolute;
      right: 15px;
      top: 15px;
  }

  .signin-form .or-bg span {
      background: #fff;
      width: 42px;
      padding: 0px 7px;
      font-size: 25px;
      line-height: 29px;
      color: #323232;
  }

  .signin-form .right-login {
      width: 100% !important;
      height: 100% !important;
  }

  .sign-form:before {
      content: "";
      position: absolute;
      background-image: url("./../../../public/assets/images/signin/sign-circle.png");
      background-repeat: no-repeat;
      transform: rotate(308deg);
      width: 259px;
      height: 143px;
      z-index: 9999;
      background-size: contain;
      top: -112px;
      left: -116px;
      filter: blur(13px);
  }

  .signin-form .cloud-sign {
      position: absolute;
      bottom: -65px;
      right: -94px;
      width: 358px;
  }

  .signin-form .square-box {
      top: -68px;
      position: absolute;
      right: 44px;
      width: 159px;
  }

  .signin-form .vector-sign {
      position: absolute;
      top: 0;
      right: -115px;
  }

  .signin-form .circle-sign {
      position: absolute;
      top: 128px;
      width: 57px;
      right: -38px;
  }

  /* .form-control:focus {
    border-color: #d1d1d1 !important;
} */
  /* forget modal */
  .forgot-body {
      width: 550px;
      margin: 0px auto;
  }

  .forgot-header {
      padding-top: 40px;
      padding-bottom: 10px;
      border-bottom: none !important;
  }

  .forgot-title {
      color: #020003;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: 0em;
  }

  .forgot-body p {
      color: #303030;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: center;
  }

  .forgot-label {
      color: #000;
      font-size: 18px;
  }

  .forgot-input {
      border: 1px solid #d1d1d1;
      background: #ffffff;
      box-shadow: 4px 4px 100px rgba(0, 0, 0, 0.07);
      padding: 18px;
      font-size: 16px;
      font-weight: 400;
      border-radius: 10px;
      height: 54px;
  }

  .forgot-input:focus {
      border-color: #d1d1d1 !important;
      outline: 0;
      box-shadow: unset;
  }

  .modal-radius {
      border-radius: 30px;
      box-shadow: 4px 4px 100px 0px rgba(0, 0, 0, 0.10);
  }

  .verify-modal-box .reset-otp {
      display: inline-block;
      width: 60px !important;
      height: 60px;
      text-align: center !important;
      border-radius: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
  }

  .resend-code {
      margin-top: 30px;
      margin-bottom: 0px;
      cursor: pointer;
  }



  /* responsive login */
  @media (min-width: 993px) and (max-width: 1400px) {
      .signin-form .list-styled li a {
          font-size: 24px;
      }

      .signin-form .channels-box .channel-text h4 {
          font-size: 20px !important;
      }
  }

  @media (min-width: 1024px) and (max-width: 1360px) {
      .signin-form .channels-box {
          min-height: 250px;
      }

      .signin-form .channels-box .channel-text h4 {
          font-size: 20px !important;
      }
  }

  @media only screen and (max-width: 992px) and (min-width: 1400px) {
      .signin-form .channels-box .channel-text h4 {
          font-size: 20px !important;
      }
  }

  @media (max-width: 1024px) {
      .login-wrapper .signin-form {
          height: auto;

      }

      .signin-form .cloud-sign,
      .square-box,
      .vector-sign,
      .circle-sign,
      .sign-form:before {
          display: none;
      }
  }

  @media (max-width: 767px) {
      .signin-form .list-styled li a {
          font-size: 21px;
      }

      .signin-form .tab-content {
          padding: 37px 0 34px;
      }

      .sign-form:before {
          display: none;
      }

      .sign-form .or-bg span {
          font-size: 19px;
      }
  }

  @media (max-width: 375px) {

      /* .signin-form  .social-link {
        display: initial !important;
    } */
      .signin-form .common-btn {
          padding: 18px 50px;
      }

      .signin-form .or-bg span {
          font-size: 19px;
      }
  }

  @media (min-width:992px) and (max-width:1700px) {
      .signin-form .square-box {
          top: -34px;
          position: absolute;
          right: 45px;
          width: 80px;
      }

      .signin-form .right-login {
          object-fit: cover;
      }

      .signin-form .cloud-sign {
          position: absolute;
          bottom: 0px;
          right: -73px;
          width: 193px;
      }
  }



  .logo-sign {
      margin: 10px 0 32px;
  }

  @media (max-width: 991px) {
      .login-wrapper {
          height: 100%;
          padding: 45px 0;
      }
  }