.stream-btn .btn-blue {
    padding: 16px 42px;
    border-radius: 6px 20px;
}

h4 {
    font-weight: 600;
}

.btn-white {
    padding: 16px 42px !important;
    color: var(--btn-color) !important;
    background-color: var(--white) !important;
    border: 1px solid #E3E0FE;
    border-radius: 6px 20px;
}

.btn-white:hover,
.btn-white:active {
    /* padding: 16px 42px; */
    color: var(--white) !important;
    background-color: var(--theme_blue) !important;
}