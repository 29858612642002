.main-search-main .latest-video {
  background: #fdfdfe;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
  border-radius: 28px;
  width: 100%;
}

.main-search-main .latest-vid-head {
  font-weight: 500;
  color: #000;
}

.main-search-main .latest-vid-img {
  border-radius: 16px;
  width: 100%;
}

.main-search-main .play-vid-btn {
  position: absolute;
  top: 45%;
  left: 45%;
}

.main-search-main .latest-video-info p {
  text-align: center;
  letter-spacing: -0.408px;
  color: #303030;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  height: 2.7em;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.main-search-main .video-time {
  position: absolute;
  bottom: 0px;
  right: 38px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

/* video-modal-css  */
.main-search-main .video-modal-header {
  border-bottom: 0px;
}

.main-search-main .modal-video-content video {
  width: auto;
  height: auto;
  border-radius: 16px;
  object-fit: contain;
  border: 3px solid #8e8e8e;
  max-width: 100%;
  max-height: 600px;
}

.main-search-main .video-cross {
  position: absolute;
  top: 10px;
  right: 5px;
}

.main-search-main .body-radius {
  border-radius: 20px;
  border: none !important;
  background: transparent;
  width: auto;
}

.main-search-main .video-cross-img {
  max-width: 75% !important;
  opacity: 1 !important;
  box-shadow: 0px 0px 2px 1px #000000;
  border-radius: 20px;
  padding: 4px;
  background: #000;
}

.main-search-main .modal-video-content {
  border-radius: 20px;
}

.main-search-main .chanel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.main-search-main .see-all {
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.main-search-main .chanel-content {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 15px;
  border-radius: 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
  position: relative;
  border-radius: 24px 5px;
}

.main-search-main .like-img {
  position: absolute;
  right: 15px;
  top: 10px;
}

.main-search-main .chanel-content .chanl-img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
}

/* .chanel-text p{
      line-height: 10px;
  } */
.main-search-main .bite-img {
  border-radius: 20px;
}

.main-search-main .trending-text {
  position: absolute;
  border-radius: 13px 4px;
  background: var(--theme_pink);
  color: var(--white);
  padding: 6px 14px;
  left: 23px;
  top: 28px;
}

.form-allchannel-search-cus  {
  width: 50%;
}
.form-allchannel-search-cus input{
border: 1px solid #E3E0FE !important;
border-radius: 48px;
height: 60px;
padding-left: 61px;
width: 100%;
position: relative;
}
@media ((min-width: 1200px) and (max-width: 1400px)) {
  .latest-video-info p {
    font-size: 18px;
  }

  .chanel-content .chanl-img {
    width: 60px;
    height: 60px;

  }
}

@media (max-width:767px){
  .form-allchannel-search-cus  {
    width: 100%;
  }
}