
.popular-channel button {
  display: none !important;
}

.popular-channel .owl-height {
  height: auto !important;
}

.popular-channel .popular-box {
  width: 400px !important;
  margin: auto;
  border: 5px solid var(--white);
  border-radius: 40px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
}

.popular-channel .owl-carousel .owl-item img.margin-img {
  text-align: center;
  margin: auto !important;
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  height: 200px;
  min-height: 200px;
  max-height: 200px;
}

.popular-channel .owl-nav button {
  position: absolute;
  top: 50%;
  background-color: #000;
  color: #fff;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.popular-channel .owl-nav button.owl-prev {
  left: 0;
}

.popular-channel .owl-nav button.owl-next {
  right: 0;
}

.popular-channel .owl-dots {
  text-align: center;
  padding-top: 15px;
}

.popular-channel .owl-dots button.owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: #ccc;
  margin: 0 3px;
}

.popular-channel .owl-dots button.owl-dot.active {
  background-color: #000;
}

.popular-channel .owl-dots button.owl-dot:focus {
  outline: none;
}

.popular-channel .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.38) !important;
}

.popular-channel .owl-nav button:focus {
  outline: none;
}

.testimonial .owl-carousel {
  display: block !important;
  width: 100%;
  z-index: 1;
}

.testimonial .owl-nav button {
  position: absolute;
  top: 50%;
  background-color: #000;
  color: #fff;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.testimonial .owl-nav button.owl-prev {
  left: 0;
}

.testimonial .owl-nav button.owl-next {
  right: 0;
}

.testimonial .owl-dots {
  text-align: center;
  padding-top: 15px;
}

.testimonial .owl-dots button.owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: #ccc;
  margin: 0 3px;
}

.testimonial .owl-dots button.owl-dot.active {
  background-color: #000;
}

.testimonial .owl-dots button.owl-dot:focus {
  outline: none;
}

.testimonial .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.38) !important;
}

.testimonial .owl-nav button:focus {
  outline: none;
}

.testimonial .owl-nav button.owl-prev,
.testimonial button.owl-next {
  position: absolute;
  bottom: 0;
  left: 10%;
  height: 0;
  width: 5%;
}

.testimonial .owl-nav button.owl-prev span {
  font-size: 48px;
  bottom: 0;
  background-color: var(--theme_blue) !important;
  color: #fff;
  line-height: 31px;
  padding: 10px 40px !important;
  border-radius: 30px 10px;
}

.testimonial .owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: inline-flex !important;
}

.testimonial .owl-nav button.owl-prev span,
.testimonial button.owl-next span {
  position: absolute !important;
  bottom: -270px !important;
  left: 0 !important;
}

.testimonial button.owl-next span {
  position: absolute;
  bottom: -310px;
}

.testimonial button.owl-next span {
  font-size: 48px;
  bottom: 0;
  background-color: var(--theme_pink) !important;
  color: #fff;
  line-height: 31px;
  padding: 10px 40px !important;
  border-radius: 30px 10px;
  margin-left: 120px;
}

.testimonial .owl-dots {
  display: none;
}

.testimonial .owl-stage-outer.owl-height {
  height: auto !important;
}


@media screen and (max-width: 480px) {

  .testimonial .owl-nav button.owl-prev span,
  .testimonial button.owl-next span {
    bottom: -210px !important;
    left: 50% !important;
  }
}

.popular-chnl-main {
  width: 200px;
  height: 200px;
  background: #f7f3fc;
  border: 7px solid #fff;
}
.popular-chnl-main:hover img {
  filter: brightness(2.5);
}
.popular-chnl-main {
  display: flex !important;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.popular-chnl-main:hover {
  background: linear-gradient(266deg, #CCBAFB
, #8E9AFF
);

}
.chnl-img-box {
  width: 160px !important;
}
@media (min-width:1300px) and (max-width:1680px){
  .popular-channel .popular-box {
    width: 318px !important;

}
}