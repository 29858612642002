/* .stream.btn-blue {
    padding: 16px 42px;
    border-radius: 6px 20px;
}

h4{
    font-weight: 600;
}

.btn-white{
    padding: 16px 42px;
    color: var(--btn-color);
    background-color: var(--white);
    border: 1px solid #E3E0FE;
    border-radius: 6px 20px;
}
.btn-white:hover,
.btn-white:active{
    padding: 16px 42px;
    color: var(--white);
    background-color: var(--theme_blue);
}
.stream-btn{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    padding: 0;
}  */

/* / chanel start / */
.chanel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.see-all {
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.chanel-content {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 15px;
    border-radius: 20px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
    position: relative;
    border-radius: 24px 5px;
}

.like-img {
    position: absolute;
    right: 15px;
    top: 10px;
}

.chanel-content .chanl-img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
}

.chanel-text p {
    line-height: 10px;
}

img.bite-img {
    border-radius: 20px;
}

.trending-text {
    position: absolute;
    border-radius: 13px 4px;
    background: var(--theme_pink);
    color: var(--white);
    padding: 6px 14px;
    left: 23px;
    top: 28px;
}

.bite-inner-img {
    width: 33px;
    height: 33px;
    border-radius: 50%;
}

.bite-relative {
    position: relative;
}

.bite-wrapper .common-btn {
    display: inline-flex;
    border-radius: 13px 4px;
    padding: 5px 40px;
    border: none;
    gap: 10px;
    align-items: center;
}

.btn-play {
    position: absolute;
    top: 50%;
    border: none;
    background-color: transparent;
    left: 15%;
    transform: translateY(-50%);
}

.bite-wrapper p {
    line-height: 20px;
    text-overflow: ellipsis;
}

.interactive .chanel-content,
.new-talent .chanel-content {
    padding: 10px 10px;
}

.btn-play img {
    width: 60px;
}

.inter-img {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    width: 45px;
    height: 45px;
}

.comming-soon .owl-carousel .owl-item img {
    width: 100% !important;
}

.comming-soon .owl-carousel button.owl-dot {
    background: #8888886e !important;
    margin: 0 3px !important;
}

.comming-soon .owl-dots button.owl-dot.active {
    background: var(--theme_blue) !important;
}

.soon-img {
    position: relative;
}

.coming-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.coming-text h3 {
    color: var(--white) !important;
}

.comming-soon .owl-dots button.owl-dot {
    width: 39.62px;
    height: 13.12px;
    border-radius: 9.37023px;
}

.challenge-video {
    position: relative !important;
}

.img-relative {
    width: 100%;
}

.video-bottom {
    position: absolute !important;
    bottom: 10px;
    left: 10px;
}

.video-bottom img {
    width: 55px;
    height: 55px;
}

.video-bottom p {
    line-height: 15px;
}

.video-bottom h6 {
    font-size: 16px;
}

.video-bg {
    position: relative;
    height: 450px;
}

.bg-image {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
}

.img-video,
.img-relative {
    border-radius: 13px;
}

.play-icon {
    position: absolute;
    background: #cc09a5;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 28px 33px;
    bottom: 20px;
    right: 10px;
}

.play-icon img {
    width: 49px;
}

.video-detail {
    display: flex;
}

.chllge-img {
    width: 63px;
    height: 63px;
    margin-right: 10px;
}

.grid-frame {
    display: grid !important;
    grid-template-columns: 300px 460px 300px 460px !important;
    gap: 30px !important;
    cursor: pointer !important;
    height: 440px !important;
}

.grid-column {
    background-color: var(--white) !important;
    padding: 10px !important;
    border-radius: 25px !important;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.14) !important;
    height: 430px !important;
}

.challene-wrapper {
    overflow-x: scroll;
}


::-webkit-scrollbar {
    display: none;
}

.challene-wrapper {
    width: 100%;
    overflow-x: scroll;
    height: 100%;
    overflow-y: hidden;
}

.comming-soon .owl-carousel .owl-nav.disabled {
    display: none !important;
}

.chanel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.comming-soon .owl-carousel {
    display: block !important;

}

@media screen and (max-width:1600px) {
    .chanel-wrapper {
        width: 100%;
        overflow-x: scroll;
    }

}

@media screen and (max-width:1200px) {
    .chanel-content .chanl-img {
        width: 60px;
        height: 60px;
    }

    .bite img.bite-img,
    .new-talent img.bite-img,
    .interactive img.bite-img {
        width: 100%;
    }

    /* .bite  .chanel-content,
    .new-talent  .chanel-content,
    .interactive .chanel-content{
        flex-wrap: wrap;
    } */
    .bite .bite-relative,
    .new-talent .bite-relative,
    .interactive .bite-relative {
        width: 100%;
    }

    .new-talent .bite-wrapper,
    .bite .bite-wrapper {
        margin: auto;
    }

    .btn-play {
        top: 120px;
        left: 40%;
    }

    /* .interactive .chanel-text {
        justify-content: center;
    } */

    .interactive .chanel-content {
        text-align: center;
    }

    .interactive .chanel-content,
    .new-talent .chanel-content {
        padding: 10px 10px 30px 10px;
    }

    .bite-wrapper {
        margin: 0;
    }

    .bite .bite-img {
        max-width: 150px;
        width: 100%;
        height: 150px;
        object-fit: cover;
    }

    .bite .bite-img {
        max-width: 100%;
    }

}

@media screen and (max-width:991px) {
    .stream-btn {
        gap: 10px;
    }

    .new-talent img.bite-img,
    .interactive img.bite-img {
        width: auto;
    }

    .new-talent .chanel-content,
    .interactive .chanel-content {
        flex-wrap: wrap;
    }

    .new-talent .bite-relative,
    .interactive .bite-relative {
        width: auto;
    }

    .new-talent .bite-wrapper {
        margin: 0;
    }

    .interactive .chanel-text {
        justify-content: unset;
    }

    .interactive .bite-wrapper {
        text-align: initial;
    }

    .btn-play {
        top: 80px;
        left: 10%;
    }

    .video-bg:after {
        width: 100%;
    }


    ::-webkit-scrollbar {
        width: 20px;
        display: block;
        height: 10px;
    }


    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px var(--btn-color);
        border-radius: 5px;
    }


    ::-webkit-scrollbar-thumb {
        background: var(--btn-color);
        border-radius: 10px;
    }

    .play-icon img {
        width: 38px;
    }

    .play-icon {
        width: 90px;
        height: 90px;
        padding: 28px 33px;
        bottom: 30px;
        right: 0;
    }

    .response-padding {
        padding: 15px 0;
    }

    .response-col {
        padding: 15px 10px;
    }

    .channel .g-5,
    .gy-5 {
        --bs-gutter-y: 1rem !important;
    }

    .comming-soon .owl-carousel .owl-item .soon-img img {
        width: 100% !important;
        height: 300px !important;
    }

    .owl-carousel .owl-item .coming-text img {
        width: 60% !important;
        margin: auto;
    }

}

.comming-soon .owl-dots {
    height: 150px;
}

@media screen and (max-width:767px) {
    .chanel-content {
        gap: 37px;
    }

    .new-talent img.bite-img,
    .interactive img.bite-img {
        width: auto;
    }

    .new-talent .chanel-content,
    .interactive .chanel-content {
        flex-wrap: wrap;
    }

    .new-talent .bite-relative,
    .interactive .bite-relative {
        width: auto;
    }

    .new-talent .bite-wrapper {
        margin: 0;
    }


    .bite .bite-relative {
        width: auto;
    }

    .bite-wrapper p {
        max-width: 160px;
    }

    .btn-play img {
        width: 40px;
    }

    .btn-play {
        top: 95px;
        left: 21%;
    }
  

}

@media screen and (max-width:575px) {

    .btn-play {
        left: 15%;
    }

    .comming-soon .owl-carousel .owl-item .soon-img img {
        width: 100% !important;
        height: 300px !important;
        object-fit: initial;
    }
}

@media screen and (max-width:480px) {
    .bite .bite-relative {
        width: 50%;
    }

    .new-talent .chanel-content {
        flex-wrap: unset;
    }

    .interactive .chanel-content {
        flex-wrap: wrap;
    }

    .interactive .bite-relative,
    .interactive img.bite-img {
        width: 100%;
    }

    .interactive .bite-wrapper {
        text-align: center;
        margin: auto;
    }

    .bite-wrapper p {
        max-width: 100%;
    }

    /* .interactive .chanel-text {
        justify-content: center;
    } */

    .btn-play {
        top: 135px;
        left: 45%;
    }

    .stream-btn .btn-blue {
        padding: 10px 20px;
    }

    .comming-soon .owl-carousel .owl-item .soon-img img {
        height: 250px !important;
    }

    .owl-carousel .owl-item .coming-text img {
        width: 100% !important;
    }
}

@media screen and (max-width:420px) {
    .bite .bite-relative {
        width: 100%;
    }

    .new-talent .chanel-content {
        flex-wrap: wrap;
    }

    .new-talent .bite-relative,
    .interactive .bite-relative {
        width: 100%;
    }

    .new-talent .bite-wrapper {
        margin: auto;
    }

    .new-talent img.bite-img,
    .interactive img.bite-img {
        width: 100%;
    }

    .btn-play {
        top: 130px;
        left: 45%;
    }
}

@media (max-width:330px){
    .bites-home .talent-relative {
        width: 100%;
    }
    .bite .bite-wrapper {
        padding-top: 10px;
    }
    .channel-home-main .chanel-content {
        display:flex !important;
    }
}
