iframe.ytp-more-videos-view,
iframe.ytp-scroll-min {
    display: none !important;
}
.ytp-more-videos-view {
    display: none !important;
}

.ytp-more-videos-view .ytp-suggestions {
    display: none !important;
}

.html5-video-player {
    display: none !important;
    color: transparent !important;
}

.ytp-chrome-top,
.ytp-chrome-bottom {
    display: none !important;
}

.ytp-more-videos-view .ytp-related-title {
    display: none !important;
}

.ytp-pause-overlay {
    background: transparent !important;
    display: none !important;
}

.ytp-expand-pause-overlay .ytp-pause-overlay {
    background: transparent !important;
    display: none !important;
}

.html5-video-player a {
    color: transparent !important;

}

.ytp-expand-pause-overlay .ytp-expand {
    display: none !important;
}

.ytp-embed:not(.ad-showing) .ytp-title-text {
    display: none !important;
}

.ytp-gradient-top,
.ytp-gradient-bottom {
    background-image: none !important;
    display: none !important;
}

.ytp-more-videos-view .ytp-related-title {

    display: none !important;
}

.ytp-button:not([aria-disabled=true]):not([disabled]):not([aria-hidden=true]) {
    cursor: none !important;
    display: none !important;
}

.ytp-chrome-controls .ytp-button,
.ytp-replay-button {
    display: none !important;
}

.ytp-chrome-controls .ytp-button,
.ytp-replay-button {
    display: none !important;
}

.ytp-share-icon {
    display: none !important;
}

.video-seriesdetail button {
    display: none !important;
}
