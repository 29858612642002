 /* channel page */

 .channel-img {
     filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
     width: 100px;
     height: 100px;
     border-radius: 50px;
     object-fit: cover;
     background-color: #fff;
 }

 .channels-box {
     background: rgba(253, 253, 254, 0.5);
     box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
     border-radius: 28px;
     text-align: center;
     min-height: 200px;
     padding: 25px;
 }

 .like-icon {
     position: absolute;
     top: 11px;
     right: 38px;
     z-index: 10;
 }

 .search-filter-category input {
     border: 1px solid #E3E0FE !important;
     border-radius: 48px;
     height: 60px;
     padding-left: 48px;
     width: 100%;
     z-index: 99;
 }

 .icon-search-category {
     position: absolute;
     top: 18px;
     left: 15px;
     border: 0;
     background: transparent;
 }

 .filter-category {
     position: absolute;
     top: 18px;
     right: 32px;
     background: transparent;
     border: 0;
 }