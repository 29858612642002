/****layout css***/
.sidebar-wrapper {
  position: fixed;
  height: 100vh;
  left: 0;
  width: 300px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(52px);
  -webkit-backdrop-filter: blur(52px);
  transition: all .3s ease-in-out;
  border-right: 1px solid var(--blacklight);
}

.sidebar-wrapper * {
  overflow: hidden;
  white-space: nowrap;
}

/* .sidebar-wrapper.sidebar-closed {   width: 85px;  overflow: hidden;} */

.sidebar-wrapper.sidebar-closed {
  display: none;
}

@media(max-width:1200px) {
  .sidebar-wrapper.sidebar-closed {
    display: block;
    background-color: #fff;
  }
}

/***add class***/
.main-content {
  width: calc(100% - 302px);
  margin-left: auto;
  transition: all .3s ease-in-out;
  overflow-y: scroll;
  height: 100vh;
}


.sidebar-wrapper.sidebar-closed .top-logo-sidebar {
  padding-left: 18px;
}

.sidebar-wrapper.sidebar-closed .top-logo-sidebar img.logo-text {
  display: none;
}

/* .sidebar-wrapper.sidebar-closed h4 {   display: none;} */
.sidebar-wrapper.sidebar-closed .list_access li {
  margin: 13px 18px;

}

/* .sidebar-wrapper.sidebar-closed .list_access li a {
  padding: 10px 0px;
} */

/***End**/
.top-logo-sidebar img.logo-fish {
  vertical-align: top;
  width: 80%;
  margin: 35px 35px 35px 20px;
  object-fit: contain;
}

.top-logo-sidebar img.logo-text {
  width: 100px;
  object-fit: contain;
}

.top-logo-sidebar {
  padding-left: 0px;
  padding-top: 18px;
  padding-bottom: 30px;
  vertical-align: top;
}

.list_access {
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.main-menu-text {
  padding-left: 20px;
}

.list_access li.active a {
  color: var(--theme_blue);
}

.list_access li.active {
  background: #E6E6F5;
}

/* .list_access li img {  filter: grayscale(1); width:22px;height:22px;} */
.list_access li img {
  filter: grayscale(0);
  opacity: 0.5;
  width: 40px;
  height: 24px;
}

.list_access li:hover {
  background: #E6E6F5;
}

.list_access li.active img,
.list_access li:hover img {
  opacity: 1;
}

.list_access li.active a,
.list_access li:hover a {
  color: var(--theme_blue);
}

.list_access li {
  margin: 13px 18px;
  border-radius: 15px;
  position: relative;
}

/* transition: .600s; */
.list_access li a {
  color: var(--side-text);
  display: flex;
  padding: 14px 20px;
  align-items: center;
}

span.number-count {
  position: absolute;
  right: 27px;
  background: var(--secondary-color);
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  text-align: center;
  line-height: 25px;
  font-size: 13px;
  color: #fff;
}

.sidebar-wrapper.sidebar-closed span.number-count {
  display: none;
}

.bottomn-fixed {
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
}

/* active */
.list_access li .active {
  background: #E6E6F5 !important;
  color: var(--theme_blue) !important;
}



.list_access li .active img {
  opacity: 1 !important;
}

/***top-header-dashboard***/
.top-dashboard {
  padding: 20px;
  display: flex;
}

.navbar-item {
  display: flex;
  align-items: center;
}

 .icons-zie {
  font-size: 25px;
  opacity: 0.10;
}

.icons-search-dt input.search_icons_top {
  background: var(--blacklight);
  border: 0px;
  padding: 12px 20px;
  border-radius: 14px;
  min-width: 270px;
}

.icons-search-dt i.fa.fa-search {
  position: absolute;
  right: 15px;
  color: var(--sliver-text);
  top: 15px;
}

span.number-notification {
  position: absolute;
  right: -8px;
  background: var(--secondary-color);
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  color: #fff;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  top: -8px;
  box-shadow: 0px 0px 2px #000;
}

.close_cross {
  top: 14px;
  position: absolute;
  right: 11px;
  font-size: 23px;
  cursor: pointer;
  color: var(--theme_pink) !important;
}

@media(max-width:1200px) and (min-width:992px) {

  /* .sidebar-wrapper {  width: 250px;} */
  .top-dashboard {
    padding: 20px 20px;
    display: flex;
  }

  .top-logo-sidebar {
    padding-left: 29px;
    padding-top: 21px;
    padding-bottom: 21px;
  }

  .main-menu-text {
    padding-left: 38px;
  }

  .list_access li {
    margin: 11px 17px;
    padding: 15px 20px;
  }
}

@media(max-width:1200px) {
  .user-content {
    display: none;
  }

  .close_cross {
    display: block !important;
  }

  .icons-zie {
    opacity: 1 !important;
  }
}

@media(max-width:1200px) {
  .sidebar-wrapper {
    width: 0px;
    z-index: 4;
  }

  .main-content {
    width: 100%;
  }

  .sidebar-wrapper.sidebar-uper {
    width: 300px;
    height: 100%;
    z-index: 4;
  }

  /* .header-top {
    left: 0 !important;
    width: 100% !important;
  } */

  .close_cross {
    color: #bb3b98;
    font-size: 24px !important;
    margin-right: 5px;
  }
}

@media(min-width:1200px) {
  div .icons-zie {
    display: block;
    opacity: 1;
  }
}

@media(max-width:767px) {
  .top-dashboard .mobile-search {
    display: block;
    position: absolute !important;
    left: 0px;
    right: 0px;
    width: 100%;
    background: var(--primary-theme);
    top: 2px;
    height: 100px;
    z-index: 2;
  }

  .icons-search-dt input.search_icons_top {
    width: 98%;
    left: 0px;
    position: relative;
    top: 20px;
  }

  .search-icons {
    display: none;
  }

  .icons-search-dt i.fa.fa-search {
    top: 32px;
  }

  .top-dashboard {
    padding: 19px 15px;
    display: flex;
  }

  .icons-zie {
    font-size: 25px !important;
  }

  .user-image {
    width: 40px;
    height: 40px;
  }

  /* .sidebar-wrapper .top-logo-sidebar {  display: none;} */
  .sidebar-wrapper.sidebar-uper .top-logo-sidebar {
    display: block;
  }

  .form-search-second {
    display: none;
  }
}


@media(max-width:350px) {
  h3 {
    font-size: 17px;
  }
}

.icon-search {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 11px;
  border: none;
  height: 100%;
  padding: 5px 13px;
  width: auto;
  background: transparent;
}

.form-search-second input {
  border: 1px solid #E3E0FE !important;
  border-radius: 48px;
  height: 60px;
  padding-left: 48px;
  width: 450px;
  z-index: 99;
}

/* .login-info {
  border-radius: 82px;
  border: 0;
  grid-template-columns: 50px auto;
  grid-gap: 6px;
  width: 155px;
  padding: 7px 8px;
  text-align: left;
  background-color: transparent;
  display: flex;
  align-items: center;
} */
.login-info {
  border-radius: 82px;
  border: 0;
  grid-template-columns: 50px auto;
  grid-gap: 10px;
  /* width: 180px; */
  padding: 7px 8px;
  text-align: left;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.login-info img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.login-info p {
  margin-bottom: 0px;
  line-height: 1.1;
}

.new-red-notification {
  position: absolute;
  top: -9px;
  border-radius: 50%;
  right: 0px;
  width: 18px;
  height: 18px;
  background: #29bfc0;
  font-size: 9px;
  line-height: 16px;
  display: inline-block;
  text-align: center;
  color: #fff;
}

.notify-bg {
  background: var(--theme_pink);
  height: 50px;
  width: 50px;
  padding: 5px 1px;
  border-radius: 10px;
}

.notification-icon {
  position: relative;
  /* top: 7px;
  left: 11px; */
  width: 24px;
  height: 24px;
}

.filter-search {
  position: absolute;
  top: 1px;
  right: 24px;
  border: none;
  height: 100%;
  padding: 5px 13px;
  width: auto;
  background: transparent;
}

/* .header-top {
  width: calc(100% - 300px);
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  right: 0;
  left: 300px;
  height: 120px;
  z-index: 3;
} */
.header-top {
  width: calc(100% - 300px);
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  /* right: 0; */
  /* left: 300px; */
  height: 120px;
  z-index: 999;

}

.main-inner-content {
  height: 100%;
  position: relative;
  top: 124px;
  margin: 0 10px 0 10px;
}



/* dropdown notification start */
.notify-drop .dropdown-menu.show {
  width: 352px;
  height: auto;
  padding: 23px;
  border: none;
  filter: drop-shadow(0px 0px 6px #c6c4c4);
  /* transform: translate(10px, 60px) !important; */
  position: relative;
}

.notify-drop .dropdown-menu.show:before {
  content: "";
  background-image: url(./../../../public/assets/images/header/Polygon.svg);
  background-repeat: no-repeat;
  position: absolute;
  width: 30px;
  height: 30px;
  top: -11px;
  right: 24px;
  background-position: top right;
}

.notify-grid {
  display: grid;
  grid-template-columns: 80px 1fr;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 14px 12px;
}

.notify-grid img {
  width: 37px;
  height: 37px;
  object-fit: cover;
}

.user-notify-bg {
  width: 62px;
  height: 62px;
  background: #F1F1F1;
  border-radius: 6px;
  padding: 11px;
}

.notify-grid p {
  line-height: 25px;
}

.notify-drop .dropdown-toggle::after {
  display: none !important;
}

/* dropdown notification end */

/* forgetmodal start */
.forgot-body {
  width: 550px;
  margin: 0px auto;
}

.forgot-header {
  padding-top: 40px;
  padding-bottom: 10px;
  border-bottom: none !important;
}

.forgot-title {
  color: #020003;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
}

.forgot-body p {
  color: #303030;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
}

.forgot-label {
  color: #000;
  font-size: 18px;
}

.forgot-input {
  border: 1px solid #d1d1d1;
  background: #ffffff;
  box-shadow: 4px 4px 100px rgba(0, 0, 0, 0.07);
  padding: 18px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  height: 54px;
}

.forgot-input:focus {
  border-color: #d1d1d1;
  outline: 0;
  box-shadow: unset;
}

.modal-radius {
  border-radius: 30px;
}

@media screen and (max-width: 992px) {
  .forgot-body {
    width: 100%;
    margin: 0px auto;
  }
}

@media (max-width:390px) {
  .user-drop .dropdown-menu.show {
    width: 238px;
  }
}

/* forgetmodal end */


/* .border-edit-modal {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 6px;
} */



/* new side */

.main-content.content-open-uper.content-open {
  width: 100%;
  ;
}

.bar-icon-cus {
  display: block !important;
}


@media (min-width:1201px) {
  .bar-icon-cus {
    display: none !important;
  }

  .close_cross {
    display: none;
  }

  .right-icon-cus {
    display: block;
  }

  .main-content.content-open-uper.content-open .header-top {
    width: 100% !important;
  }

  .sidebar-left-icon {
    display: none;
  }
}

.right-icon-cus {
  display: none;
}

.main-content.content-open-uper.content-open .right-icon-cus {
  transform: rotate(179deg);
  /* transition-duration: 3s; */
}

.sidebar-wrapper .main-content.content-open-uper.content-open .bars-check-list {
  transition-duration: 3s;
  transform: rotate(90deg);
}


.right-icon-cus,
.bars-check-list {
  color: var(--theme_pink);
}


@media (max-width:1200px) {
  .header-top {
    width: 100%;
  }
}


/** Profile Modal css **/

.user-drop .dropdown-menu.show {
  width: 352px;
  height: auto;
  padding: 23px;
  border: none;
  filter: drop-shadow(0px 0px 6px #c6c4c4);
  /* position: relative; */
}

.user-drop .dropdown-menu.show:before {
  content: "";
  background-image: url(./../../../public/assets/images/header/Polygon.svg);
  background-repeat: no-repeat;
  position: absolute;
  width: 30px;
  height: 30px;
  top: -11px;
  right: 24px;
  background-position: top right;
}

.user-drop .profile-upload img {
  width: 97px;
  height: 97px;
  border: 2px solid var(--theme_pink);
  border-radius: 50px;
  object-fit: cover;
}

.border-edit {
  height: 54px;
  border: 1px solid #D1D1D1 !important;
  color:#000;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 32px 0 16px;
}

.arrow-icon {
  position: absolute;
  top: 7px;
  right: 15px;
}

/* new css  */
@media(max-width:578px) {
  .sidebar-wrapper.sidebar-uper {
    z-index: 999999;
  }

  .top-logo-sidebar img.logo-fish {
    margin: 13px 35px 35px 20px;
  }

  .close_cross {
    top: 30px;
    position: absolute;
    left: 11px;
    font-size: 30px !important;
  }

  .notify-drop .dropdown-menu.show:before {
    display: none;
  }

  .header-search .form-inline {
    width: 30px;
    border: 0;
    height: 30px;
  }

  .login-info p {
    display: none !important;
  }

  .login-info img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
  }

  .header-search .form-inline {
    width: 30px !important;
    border: 0;
    height: 30px !important;
  }

  .search-img {
    width: 15px;
    height: 18px;
  }

  .search-icon {
    height: 30px !important;
    width: 30px !important;
    border-radius: 7px !important;
    padding: 0px !important;
  }

  .top-dashboard {
    padding: 17px 20px 0px 20px !important;
    display: flex;
    align-items: center;
  }

  .login-info {
    width: auto;
  }

  .dropdown-menu.edit-profile.show {
    transform: translate(-175px, -12px);
    -webkit-transform: translate(-175px, -12px);
    -moz-transform: translate(-175px, -12px);
    -ms-transform: translate(-175px, -12px);
    -o-transform: translate(-175px, -12px);
  }

  .user-drop .dropdown-menu.show {
    width: 280px;
  }

  .logo-responsive-head img {
    width: 80%;
    height: auto;
    display: block !important;
  }
}

/* profile */
.dropdown-menu.edit-profile.show {
  transform: translate(-222px, -12px);
}

@media(max-width:400px) {
  .dropdown-menu.edit-profile.show {
    transform: translate(-175px, -12px);
  }

  .user-drop .dropdown-menu.show {
    width: 243px;
  }

  .notify-drop .dropdown-menu.show {
    width: 264px;
    transform: translate3d(-40px, 48px, 0px);
  }
}

.logo-responsive-head img {
  display: none;
}

@media(max-width:300px) {
  .logo-responsive-head img {
    width: 90%;
  }
}