.stream-btn .btn-blue {
    padding: 16px 42px;
    border-radius: 6px 20px;
}

h4 {
    font-weight: 600;
}

.btn-blue {
    padding: 16px 42px;
    border: 1px solid #E3E0FE;
    border-radius: 20px 6px;
}

.btn-white:hover,
.btn-white:active {
    /* padding: 16px 42px; */
    color: var(--white);
    background-color: var(--theme_blue);
}

.see-all {
    border: none;
    background-color: transparent;
    outline: none;
    box-shadow: none;
}

.epsiode-slider .owl-carousel .item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.epsoide-slider-wrapper {
    position: relative;
    width: 100%;
}

/* .epsoide-title {
    position: absolute;
    bottom: 0;
    left: 12px;
} */

.trending-number {
    position: absolute;
    top: 10px;
    right: 30px;
}

.eps-play {
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%);
}

.epsiode-slider .owl-carousel .owl-item .eps-play img {
    width: 50px !important;
}

.crew-caste p {
    line-height: 20px;
}

.crew-caste img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.crew-caste {
    width:150px;
}

.chanel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.trending-play {
    position: absolute;
    bottom: 0;
    right: 10px;
}

.epsoide-slider-wrapper .like-btn {
    background: rgba(188, 188, 188, 0.3);
    backdrop-filter: blur(6px);
    color: var(--white);
    /* width: 55px; */
    border-radius: 10px;
    text-align: center;
    padding: 12px;
    margin: auto;
}

.like-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

 .epsiode-slider .owl-carousel .owl-item .movie-more {
    object-fit: cover !important;
    width: 100% !important;
    height: 290px !important;
    min-height: 290px !important;
    max-height: 290px !important;
} 


.more-movies .owl-dots,
.more-movies .owl-nav,
.trailer .owl-dots,
.trailer .owl-nav,
.epsiode .owl-dots,
.epsiode .owl-nav {
    display: none;
}


/* video-bannr start */
.bgwhite {
    background: #FDFDFE;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
    border-radius: 42px;
    padding: 10px 10px;
    width: 290px;
}

.mvie-title {
    width: 50px;
    height: 50px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

.mv-title {
    width: 50px;
    height: 50px;

}

.movie-wrapper {
    padding: 40px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(29px);
}

.frame-video-img img {
    width: 100%;
}
 

/* video-bannr end */

@media screen and (max-width:1600px) {
    .crew-caste {
        width:200px;
    }
}
@media screen and (max-width:1200px) {
    .movie-detail .stream-btn .btn-blue {
        padding: 12px 30px;
        border-radius: 6px 20px;
    }

    .crew-wrpper {
        gap: 1rem !important;
    }

    .movie-wrapper {
        padding: 20px 30px;
    }

    .bgwhite .gap-4 {
        gap: 1rem !important;
    }

    .movie-wrapper .gap-5,
    .movie-detail .gap-5 {
        gap: 30px !important;
    }

    .mvie-title {
        width: 40px;
        height: 40px;
    }

    .mv-title {
        width: 40px;
        height: 40px;
    }

    .bgwhite {
        width: 235px;
    }

    .movie-detail .stream-btn {
        gap: 10px;
    }

    
}

@media screen and (max-width:991px) {

    .bgwhite .gap-4 {
        gap: 0.5rem !important;
    }

    .movie-wrapper .gap-5,
    .movie-detail .gap-5 {
        gap: 30px !important;
    }
    .width-img{
        width: 180px;
    }
}

@media screen and (max-width:767px) {
    .bgwhite {
        width: 225px;
    }

    .movie-wrapper .gap-5,
    .movie-detail .gap-5 {
        gap: 15px !important;
    }

    .bgwhite h4 {
        font-size: 18px !important;
    }

    .bgwhite {
        width: 200px;
    }

    .crew-caste img {
        width: 80px;
        height: 80px;
    }

    .width-img{

    }
}

@media screen and (max-width:420px) {
    .movie-detail .stream-btn .btn-blue {
        padding: 12px 20px;
    }
}

/* 12-june-23 */
.frame-video-main .frame-video-img .video-frame-fluid {
    height: 500px;
    object-position: top center;
    object-fit:cover;
}


/* series-deatils 12-june-23 */
.epsiode-slider .owl-carousel .owl-item .movie-more {
    padding: 9px;
}
.epsoide-title {
    left: 24px !important;
}
.crew-caste img {
    border-radius: 50%;
}

.epsoide-slider-custom img {
    border-radius: 13px;
}
.epsiode-slider-details {
    display: flex;
    width: 100% !important;
    overflow-x: scroll;
}
.epsoide-slider-custom {
    margin: 0px 10px;
}
.epsiode-slider-details::-webkit-scrollbar {
    display: block !important;
    width: 0px ;
    height: 0px;
}
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: #ca3392; 
    border-radius: 10px;
  }
  /* .trending-number-epi {
    position: absolute;
    top: 0;
    right: 12px;
} */
.play-epi-slider {
    width: 60px;
    height: 60px;
    object-fit: contain;
}
.epsoide-slider-custom .episode-img-thumb{
    border-radius: 13px 13px 0 0;
    width: 100%;
    height: 190px;
    object-fit: cover;
    filter: brightness(0.8);
    cursor: pointer;
    -webkit-border-radius: 13px 13px 0 0;
    -moz-border-radius: 13px 13px 0 0;
    -ms-border-radius: 13px 13px 0 0;
    -o-border-radius: 13px 13px 0 0;
}
/* trailor */
.trailor-slider-cus .movie-more {
    width: 450px;
    height: 216px;
    object-fit: cover;
    border-radius: 12px;
    filter: brightness(0.7);
    cursor: pointer;
    -webkit-filter: brightness(0.7);
}
.trailor-duration {
    position: absolute;
    bottom: 1px;
    right:14px;
}
.trailor-slide-main {
    display: flex;
    overflow-x: scroll;
    width: 100%;
}
.trailor-slider-cus {
    margin: 0px 10px;
    position: relative;
}
.trailor-play .eps-play img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}
/* muvi css */
.muvi-slider-main {
    display: flex;
    overflow-x: scroll;
    width: 100%;
}
.muvi-slider-slider{
    margin: 0px 10px;
    position: relative;
}
.muvi-slid-img {
    width: 452px;
    height: 340px;
    border-radius: 50px;
    object-fit: cover;
    filter: brightness(0.8);
}
.muvi-slider-title {
    position: absolute;
    bottom: 23px;
    left: 35px;
}
.like-btn-muvi {
    position: absolute;
    top: 23px;
    right: 18px;
    background: rgba(188, 188, 188, 0.3);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    color: var(--white);
    width: 45px;
    border-radius: 10px;
    padding: 8px 11px;
    height: 40px;
    z-index: 999;
}

/* crew- slider */
.crew-wrpper-slider{
    width: 100%;
    display: flex;
    overflow-x: scroll;
}



.latest-challdata .challenge-data {
    background: #FFFFFF;
    border-radius: 21.1711px;
    padding: 10px;
}

.latest-challdata .video-challenge .video-deatil-chall {
    height: 317px;
    object-fit: cover;
    width: 100%;
    border-radius: 10.5856px;
}

.latest-challdata .grid-data-detail {
    position: absolute;
    display: grid;
    grid-template-columns: 38px auto;
    align-items: center;
    bottom: 7px;
    gap: 10px;
    width: 100%;
    padding: 0px 9px;
}

.latest-challdata .grid-circle-img {
    filter: drop-shadow(0px 5.29278px 15.8783px rgba(0, 0, 0, 0.1));

}

.latest-challdata .grid-circle-img .grid-data-img {
    border-radius: 50%;
    width: 38px;
    height: 38px;
    border: 1.98479px solid #FFFFFF;
}

.latest-challdata .grid-data-detail p {
    line-height: 22px;
    color: #C5C5C5;
}

.latest-challdata .btn-play-video {
    position: absolute;
    left: 0;
    right: 0;
    top: 42%;
    text-align: center;
}

.latest-challdata .play-icon-data {
    width: 50px;
    height: 51px;
}

.latest-challdata .challenge-video-title p {
    font-size: 19px;
    line-height: 23px;
    font-weight: 500;
    padding: 13px 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.chat-listing {
    display: grid;
    align-items: start;
    grid-template-columns: 45px auto 20px;
    gap: 12px;
}
.profile-chat img {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    border: 2px solid #ca3392;
    object-fit: cover !important;
}
.chat-right {
    border-radius: 30px;
    background: #FFF;
    height: 100%;

}
.chat-message {
    padding: 10px 27px 27px;
    height: 600px;
    overflow-y: auto;
}
.chat-heading {
    border-radius: 30px 30px 0px 0px;
    background: #F0F0F0;
    padding: 18px 27px;
}
.chat-bottom .profile-bottom {
    width: 54px;
    height: 54px;
    border-radius: 50px;
    object-fit: cover;
    border: 1px solid #07143A;
}
.chat-bottom {
    border-radius: 50px;
    background: #FFF;
    box-shadow: 4px 4px 100px 0px rgba(0, 0, 0, 0.10);
    height: 70px;
    display: flex;
    align-items: center;
    position: absolute;
    width: 92%;
    bottom: 27px;
    padding: 18px;
    gap: 11px;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
}
.chat-bottom input {
    font-size: 19px;
}
.para-chat{
    color:#5F5F5F;
}
.chat-time{
    color: #757D7E;
}
.challenge-chat .chat-listing {
    display: grid;
    align-items: center;
    grid-template-columns: 26px auto;
    gap: 7px;
}
.challenge-chat .profile-chat img {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    border: 2px solid #ca3392;
    object-fit: cover;
}
.challenge-chat {
    height: 100%;
}

.series-chat .chat-message {
    padding: 10px 27px 27px;
    height: 350px !important;
    overflow-y: auto
}

.series-chat .chat-right,.challenge-chat .chat-right  {
    border-radius: 0px;
}

.common-btn.shop-trailor-btn {
    display: flex;
    align-items: center;
}
.common-btn.shop-trailor-btn {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 32px;
    right: 15px;
}
.shop-trailor-img {
    width: 20px !important;
    height: 20px !important;
    margin-right: 5px;
}

.frame-video-img.shop-trailor{
position:relative;
}