.home-slider {
  height: 100%;
}

.side-img li.active {
  border: 2px solid #fff;
  border-radius: 17px;
}

.side-img li img {
  border-radius: 17px;
  border: 2px solid transparent;
  cursor: pointer;
}

.home-image {
  position: relative;
}

.home-img {
  width: 100%;
}

.slider-img {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  border-radius: 40px;
}

.home-wraper-text {
  position: absolute;
  top: 10px;
  left: 45px;
}

.home-image .profle-img {
  width: 60px;
  height: 60px;
}

.remov-height {
  line-height: 10px;
}

.home-bottom-text {
  position: absolute;
  bottom: 20px;
  left: 45px;
  width: 100%;
}

.home-bottom-text .trending-text {
  border-radius: 20px 5px;
  background: var(--theme_pink);
  color: var(--white);
  padding: 7px 20px;
  width: 120px;
}

.home-bottom-text .btn-white {
  padding: 11px 53px !important;
  color: var(--black);
  background-color: var(--white);
  border: 1px solid #e3e0fe;
  border-radius: 20px 6px;
}

.slider-img {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  border-radius: 40px;
  overflow-y: scroll;
  height: 100%;
}

.home-image {
  position: relative;
}

.video-bottom,
.home-bottom-text .trending-text {
  position: unset !important;
}
.home-bottom-text {
  text-align: initial;
}
.white {
  color: var(--white);
}
.episode-carousel-main .movie-more {
  border-radius: 0px;
}
.episode-carousel-main .thumbs-wrapper li.thumb.selected img {
  border-radius: 5px;
  padding: 0px !important;
}
@media screen and (max-width: 1600px) {
  .slider-img {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    border-radius: 40px;
    overflow-y: scroll;
    height: 100%;
  }

  .home-image {
    overflow: hidden;
  }
}

@media screen and (max-width: 1200px) {
  .home-bottom-text .trending-text {
    width: 130px;
  }

  .home-bottom-text .btn-white {
    padding: 10px 33px !important;
  }

  .side-img li img {
    width: 150px;
  }

  .home-image .profle-img {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 991px) {
  .episode-carousel-main .epsoide-slider-wrapper .movie-more {
    border-radius: 0px !important;
  }
  .slider-img {
    height: 100%;
    overflow: scroll;
  }

  .home-bottom-text .trending-text {
    width: 100px;
    font-size: 14px;
  }

  .home-bottom-text .btn-white {
    padding: 4px 12px !important;
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {

  /* .btn-white,
  .side-img li img {
    display: none;
  } */
  .home-bottom-text,
  .home-wraper-text {
    left: 25px;
  }

  .home-bottom-text {
    bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
  .side-img li img {
    width: 100px;
  }

  .home-bottom-text h2 {
    font-size: 15px;
  }

  /* .trending-text {
    display: none;
  } */
}


.carousel.carousel-slider {
  position: relative;
}
.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
  position: absolute;
  right: 0;
  flex-direction: column;
  display: flex;
  bottom: 50px;
}
.carousel .thumb {
  display: flex !important;
padding: 0 !important;
  width: 218px !important;
  height: 117px;
  border-radius: 10px !important;
  object-fit: cover !important;
}

.carousel .thumb li img {
  border-radius: 10px;
 
}
.carousel .control-arrow {
  display: none;
}
ul.thumbs.animated {
  transform: none !important;
  max-height: 504px;
  overflow-y: scroll;
}
li.thumb {
  margin-bottom: 17px;
}
.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid #fff !important;
}
.carousel .thumb {
  border:0 !important;
}
ul.control-dots {
  visibility: hidden;
}
.detail-banner-slide {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

@media(max-width:1600px){
  .home-bottom-text .trending-text {
    padding: 7px 12px 7px 17px;

}
}
@media (max-width:767px){
  ul.control-dots {
    visibility:visible;
  }
  .carousel .thumbs-wrapper {
display: none;
}
.episode-carousel-main .button-home-banner {
  display: none !important;
}
ul.thumbs.animated{
  display: none;
}
}
@media(max-width:620px){
.episode-carousel-main .button-home-banner {
    display: none !important;
}
.carousel .control-dots {
  bottom: -52px;
}

}
@media (min-width:768px) and (max-width:1299px){
  ul.thumbs.animated {
    max-height:  200px !important;
 
}
  }
  @media (min-width:1401px) and (max-width:1800px){
    ul.thumbs.animated {
      max-height: 364px !important;
  }
  }
  @media (min-width:1300px) and (max-width:1400px){
    ul.thumbs.animated {
      max-height: 204px;
  }
  }

  .carousel .control-dots .dot {
    box-shadow: 1px 1px 2px rgba(0,0,0,0.9);
    background: black;
    opacity: 0.5 !important;

}
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  background-color: var(--theme_pink);
  opacity: 1 !important;
}
/* 16-june-23 */
.episode-carousel-main .banner-video {
  height: 500px;
  object-fit: cover;
}
.button-home-banner {
  width: calc(100% - 295px);
  overflow-x: scroll;
}

.buttonflex
{
  display: -webkit-inline-box;
    width: 80%;
}

@media (min-width:767px) and (max-width:1330px){
  .button-home-banner .common-btn {
    padding: 11px 20px !important;
  }
}
@media (max-width:1024px){
  .episode-carousel-main .banner-video {
    height: 361px;
}
}
.custom-prev-arrow,
.custom-next-arrow {
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.custom-prev-arrow {
    left: 10px;
}

.custom-next-arrow {
    right: 10px;
}

.custom-prev-arrow img,
.custom-next-arrow img {
    height: 38px; 
    width: 38px; }

