.stream-btn .btn-blue {
  padding: 16px 42px;
  border-radius: 6px 20px;
}

h4 {
  font-weight: 600;
}

.category-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding: 0;
}

.category-box {
  color: var(--btn-color);
  border: 1px solid var(--border-color);
  background: rgba(253, 253, 254, 0.5);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
  border-radius: 28px 10px;
  padding: 15px 60px 15px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

/* challenge start  */
.video-wrapr {
  /* background-color: var(--white); */
  padding: 10px;
  border-radius: 25px;
}

.challenge-video {
  position: relative;
}

.img-relative {
  width: 100%;
}

.video-bottom {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.video-bottom img {
  width: 55px;
  height: 55px;
}

.video-bottom p {
  line-height: 15px;
}

.video-bottom h6 {
  font-size: 16px;
}

.video-bg {
  position: relative;
}

.bg-image {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
}

.play-icon {
  position: absolute;
  background: #cc09a5;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 28px 33px;
  bottom: 20px;
  right: 10px;
}

.play-icon img {
  width: 49px;
}

.video-detail {
  display: flex;
}

.chllge-img {
  width: 63px;
  height: 63px;
  margin-right: 10px;
}

.grid-frame {
  display: grid;
  grid-template-columns: 500px 500px 500px;
  gap: 30px;
  cursor: pointer;
  height: 440px;
}

.grid-column {
  background-color: var(--white);
  padding: 10px;
  border-radius: 25px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
  height: 430px;
  width: 100%;
  left: 5px;
}

.challene-wrapper {
  overflow-x: scroll;
}

::-webkit-scrollbar {
  display: none;
}

.challene-wrapper {
  width: 100%;
  overflow-x: scroll;
}

.comming-soon .owl-carousel .owl-nav.disabled {
  display: none !important;
}

.recomended .bite-wrapper {
  position: relative;
  width: 100%;
}

.recomended img.bite-img {
  width: 170px;
  height: 100%;
  object-fit: cover;
}
.recomended img.bite-inner-img {
  position: absolute;
  right: -16px;
  width: 70px;
  height: 70px;
  bottom: -13px;
}

.img-video,
.img-relative,
.bite-img {
  border-radius: 13px;
}

@media screen and (max-width: 1600px) {
  .recomended img.bite-inner-img {
    right: -16px;
    width: 50px;
    height: 50px;
    bottom: -13px;
  }

  .recomended img.bite-img {
    height: 150px;
    object-fit: cover;
  }
  .recomended img.popular-img {
    min-height: 190px;
    object-fit: cover;
  }

  .bite .bite-img {
    max-width: 150px;
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .chanel-content .chanl-img {
    width: 60px;
    height: 60px;
  }

  .bite img.bite-img {
    width: 100%;
  }

  /* .bite .chanel-content {
      flex-wrap: wrap;
    } */
  .bite .bite-relative {
    width: 100%;
  }

  .bite .bite-wrapper {
    margin: auto;
  }

  .btn-play {
    top: 120px;
    left: 40%;
  }

  .f24-size {
    font-size: 16px;
  }

  .recomended img.bite-inner-img {
    position: absolute;
    right: -30px;
    width: 40px;
    height: 40px;
    bottom: -20px;
  }

  .recomended img.bite-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
}

@media screen and (max-width: 991px) {
  .bite .row.gy-4 {
    --bs-gutter-y: 0 !important;
  }

  .btn-play {
    top: 80px;
    left: 10%;
  }

  .video-bg:after {
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 20px;
    display: block;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px var(--btn-color);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--btn-color);
    border-radius: 10px;
  }

  .play-icon img {
    width: 38px;
  }

  .play-icon {
    width: 90px;
    height: 90px;
    padding: 28px 33px;
    bottom: 30px;
    right: 0;
  }

  .response-padding {
    padding: 15px 0;
  }

  .response-col {
    padding: 15px 10px;
  }

  .channel .g-5,
  .gy-5 {
    --bs-gutter-y: 1rem !important;
  }

  .recomended img.bite-inner-img {
    right: 0px;
    width: 60px;
    height: 60px;
    bottom: -10px;
  }
}

@media screen and (max-width: 767px) {
  .bite .bite-relative {
    width: auto;
  }

  .bite-wrapper p {
    max-width: 160px;
  }

  .btn-play img {
    width: 40px;
  }

  .btn-play {
    top: 95px;
    left: 21%;
  }
}

@media screen and (max-width: 575px) {
  .btn-play {
    left: 15%;
  }
}

@media screen and (max-width: 480px) {
  .bite .bite-relative {
    width: 50%;
  }

  .bite-wrapper p {
    max-width: 100%;
  }

  .btn-play {
    top: 135px;
    left: 45%;
  }
}

@media screen and (max-width: 420px) {
  .bite .bite-relative {
    width: 100%;
  }

  .btn-play {
    top: 130px;
    left: 45%;
  }
}

/* 12-june-23 */
.recoomended-main-cus .dis-recommend-cus {
  background: #fff;
}

@media (max-width:1200px) {
  .recomended img.bite-inner-img {
    position: absolute;
    right: -3px;
    width: 40px;
    height: 40px;
    bottom: -8px;
  }
}


.featured-box-cus .video-bg {
  background: #fff;
  padding: 18px;
}

.featured-box-cus .video-content .img-video {
  height: 300px;
  object-fit: cover;
}


@media (max-width:1800px) {
  .category-dis-cus .category-box {
    width: 240px;
  }

  .category-box h4 {
    font-size: 16px;
  }

  .category-box img {
    width: 50px;
  }

  .category-box {
    padding: 15px 19px 15px 20px;
  }
}

@media (max-width:3300px) {
  .category-dis-cus .category-box {
    width: 240px;
  }
  .category-box h4 {
    font-size: 16px;
  }
  .category-box img {
    width: 50px;
    height: 50px;

  }
  .category-box {
    padding: 15px 19px 15px 20px;
  }
}

@media (max-width:767px) {
  .category-dis-cus .category-box {
    width: 100%;
    margin: 0;
  }

  .category-box {
    padding: 15px 25px 15px 20px;
  }
}
